<template>
  <div
    id="rolesCard"
    class="card card-custom grid-stack-item-content"
    style="min-height: 100%"
  >
    <div class="card-header" style="cursor: move">
      <div class="card-title">
        <h3 class="card-label">{{ $t("role.roles") }}</h3>
      </div>
      <div class="card-toolbar">
        <createRole></createRole>
        <editRole ref="editRole"></editRole>
        <a
          href="#"
          class="btn btn-icon btn-sm btn-hover-light-primary mr-1"
          data-card-tool="reload"
          data-toggle="tooltip"
          data-placement="top"
          :title="$t('general.reload')"
          @click="refreshCard($event)"
        >
          <i class="fal fa-arrows-rotate"></i>
        </a>
        <a
          href="#"
          class="btn btn-icon btn-sm btn-hover-light-primary"
          data-card-tool="remove"
          data-toggle="tooltip"
          data-placement="top"
          :title="$t('general.remove')"
          @click="closeCard($event)"
        >
          <i class="fal fa-xmark"></i>
        </a>
      </div>
    </div>
    <div class="card-body">
      <div id="rolesTable">
        <div class="row" style="padding: 10px">
          <div class="col-md-12">
            <b-form-input
              id="searchRoles"
              v-model="searchTitle"
              :placeholder="$t('search.searchTerm')"
              @keyup="keymonitor"
            >
            </b-form-input>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="" style="width: 100%">
                <div class="card-body py-7">
                  <!--begin::Pagination-->
                  <div
                    class="d-flex justify-content-between align-items-center flex-wrap"
                  >
                    <div class="d-flex flex-wrap mr-3">
                      <b-pagination
                        v-model="page"
                        :total-rows="totalRecords"
                        :per-page="pageSize"
                        @change="handlePageChange"
                      ></b-pagination>
                    </div>
                    <div class="d-flex align-items-center">
                      <div class="row">
                        <div class="col-md-6">
                          <b-form-select
                            v-model="pageSize"
                            :options="pageSizes"
                            class="mb-3"
                            value-field="item"
                            text-field="name"
                            disabled-field="notEnabled"
                            @change="handlePageSizeChange"
                          ></b-form-select>
                        </div>
                        <div class="col-md-6">
                          <span class="text-muted">
                            {{ $t("general.records") }}: {{ rolesMeta.total }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end:: Pagination-->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" style="padding: 10px">
          <div class="col-md-12">
            <b-table
              ref="rolesTable"
              :striped="striped"
              :responsive="responsive"
              :bordered="bordered"
              :borderless="borderless"
              :outlined="outlined"
              :small="small"
              :hover="hover"
              :dark="dark"
              :fixed="fixed"
              :foot-clone="footClone"
              :no-border-collapse="noCollapse"
              :items="roles"
              :fields="fields"
              :head-variant="headVariant"
              :table-variant="tableVariant"
              :show-empty="true"
              :empty-text="$t('table.noRecords')"
            >
              <template #cell(actions)="data">
                <b
                  class="btn btn-icon btn-light btn-sm mr-1"
                  style="display: inline; margin-left: 5px"
                  @click="editRole(data.item.id)"
                >
                  <i class="fal fa-pen"></i>
                </b>
                <b
                  class="btn btn-icon btn-light btn-sm mr-1"
                  style="display: inline; margin-left: 5px"
                  @click="deleteRole(data.item.id, data.item.name)"
                >
                  <i class="fal fa-trash"></i>
                </b>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Roles from "@/components/Admins/Roles/roles";
import createRole from "@/components/Admins/Roles/Create";
import editRole from "@/components/Admins/Roles/Edit";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";

export default {
  name: "AdminsRolesTable",
  components: {
    createRole,
    editRole
  },
  data() {
    return {
      roles: [],
      searchTitle: "",
      fields: [
        {
          key: "id",
          label: this.$t("table.id"),
          sortable: true,
          thStyle: { width: "70px" }
        },
        {
          key: "name",
          label: this.$t("table.name"),
          sortable: true,
          thStyle: { width: "150px" }
        },
        {
          key: "description",
          label: this.$t("table.description"),
          sortable: true,
          thStyle: { width: "250px" }
        },
        {
          key: "display_name",
          label: this.$t("table.nameDisplay"),
          sortable: true,
          thStyle: { width: "150px" }
        },
        {
          key: "updated_at",
          formatter: "formatDateAssigned",
          label: this.$t("table.updatedAt"),
          sortable: true,
          thStyle: { width: "180px" }
        },
        {
          key: "created_at",
          formatter: "formatDateAssigned",
          label: this.$t("table.createdAt"),
          sortable: true,
          thStyle: { width: "180px" }
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          thStyle: { width: "170px" }
        }
      ],
      rolesMeta: [],
      page: 1,
      totalPages: 10,
      pageSize: 10,
      pageSizes: [10, 20, 50, 100],
      totalRecords: 0,

      tableVariants: [
        "primary",
        "secondary",
        "info",
        "danger",
        "warning",
        "success",
        "light",
        "dark"
      ],
      striped: false,
      bordered: false,
      borderless: false,
      outlined: true,
      small: false,
      hover: true,
      dark: false,
      fixed: false,
      footClone: false,
      headVariant: "light",
      tableVariant: "",
      noCollapse: false,
      responsive: true
    };
  },
  computed: {
    ...mapGetters(["selectedClient"])
  },
  watch: {
    selectedClient: function () {
      if ($("#rolesTable").length > 0) {
        this.refreshCard();
      }
    }
  },
  mounted() {
    this.loadRoles();
    this.$root.$on("rolesTableRefresh", () => {
      this.refreshCard();
    });
  },
  created() {
    //this.interval = setInterval(() => this.RAM(), 5000);
  },
  methods: {
    keymonitor(event) {
      if (event.target.id === "searchRoles") {
        this.searchTitle = event.target.value;
        this.loadRoles();
      }
    },
    getRequestParams(searchTitle, page, pageSize) {
      let params = {};

      if (searchTitle) {
        params["title"] = searchTitle;
      }

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["size"] = pageSize;
      }

      return params;
    },
    loadRoles() {
      if (this.selectedClient) {
        const params = this.getRequestParams(
          this.searchTitle,
          this.page,
          this.pageSize
        );
        Roles.getAll(params)
          .then(response => {
            const roles = response.data.data;
            const totalPages = response.data.meta.last_page;
            const totalRecords = response.data.meta.total;
            this.rolesMeta = response.data.meta;
            this.roles = roles;
            this.totalPages = totalPages;
            this.totalRecords = totalRecords;
          })
          .catch(error => {
            console.log(error.response);
            Swal.fire(
              this.$t("general.caution"),
              error.response.data.message,
              "error"
            );
          });
      }
    },
    handlePageChange(value) {
      this.page = value;
      this.loadAdmins();
    },
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.loadRoles();
    },
    formatDateAssigned(value) {
      if (value === null) {
        return "";
      }
      var returnData = "";
      const dtm = value.split(" ");
      const date = dtm[0].split("-");
      returnData = date[2] + "." + date[1] + "." + date[0];
      if (typeof dtm[1] !== "undefined") {
        returnData += " - " + dtm[1];
      }
      return returnData;
    },
    closeCard() {
      $("#rolesCard").fadeOut(300, function () {
        $("#rolesCard").parent().remove();
      });
      this.$destroy();
    },
    refreshCard() {
      this.loadRoles();
    },
    editRole(id) {
      this.$refs.editRole.showModal(id);
    },
    deleteRole(id, name) {
      Swal.fire({
        title: this.$t("role.roleDeleteTitle"),
        text: this.$t("role.roleDeleteDescription", { name: name }),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("general.cancel"),
        confirmButtonText: this.$t("general.delete")
      }).then(result => {
        if (result.isConfirmed) {
          Roles.delete(id)
            .then(() => {
              this.$toast.fire({
                title: this.$t("role.roleDeletedDescription"),
                icon: "success"
              });
              if (this.roles.length === 1 && this.page !== 1) {
                this.handlePageChange(this.page - 1);
              } else {
                this.refreshCard();
              }
            })
            .catch(error => {
              Swal.fire("Error", error.response.data.message, "error");
            });
        }
      });
    }
  }
};
</script>
