<template>
  <div id="usersCard" class="card card-custom grid-stack-item-content">
    <div class="card-header" style="cursor: move">
      <div class="card-title">
        <h3 class="card-label">{{ $t("users.usersManagement") }}</h3>
      </div>
      <div class="card-toolbar">
        <button class="btn btn-primary btn-hover" @click="createUser">
          <i class="fal fa-plus"></i>
          {{ $t("users.userCreate") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body column">
      <TableWrapper :meta="meta" param-prefix="users" @reload-data="loadUsers">
        <b-table
          responsive
          :items="users"
          :fields="fields"
          class="dataTable table table-head-custom"
          :show-empty="true"
          :empty-text="$t('table.noRecords')"
        >
          <template #cell(actions)="data">
            <b
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="showDetails(data.item.id)"
            >
              <i class="fal fa-pen"></i>
            </b>
            <b
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="deleteUser(data.item.id, data.item.name)"
            >
              <i class="fal fa-trash"></i>
            </b>
          </template>
          <template #cell(name)="data">
            <div class="row flex-wrap py-3">
              <div class="col-auto symbol symbol-50 py-0">
                <div
                  class="symbol-label symbol-label-profile"
                  :style="
                    data.item.profile_picture && data.item.profile_picture.length !== 0
                      ? 'background-image: url(data:' +
                        data.item.profile_picture.mimeType +
                        ';base64,' +
                        data.item.profile_picture.base64 +
                        '); background-color: #ffffff;'
                      : ''
                  "
                ></div>
              </div>
              <div class="col-auto flex-grow-1 py-0">
                <span class="d-block">{{ data.item.name }}</span>
                <span class="text-muted">{{ data.item.email }}</span>
              </div>
            </div>
          </template>
          <template #cell(active)="data">
            <span
              v-if="data.item.active"
              class="label label-lg label-light-success label-inline"
            >
              {{ $t("general.active") }}
            </span>
            <span v-else class="label label-lg label-light-danger label-inline">
              {{ $t("general.inactive") }}
            </span>
          </template>
          <template #cell(language)="data">
            <span v-if="data.item.language">
              {{ data.item.language.label }}
            </span>
          </template>
        </b-table>
      </TableWrapper>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import $ from "jquery";
import Users from "@/components/Projects/Settings/Users/users";
import { mapGetters } from "vuex";
import TableWrapper from "@/components/Tools/TableWrapper";

export default {
  name: "ProjectsSettingsUsersTable",
  components: { TableWrapper },
  data() {
    return {
      isBusy: false,
      users: [],
      meta: {},
      searchTitle: "",
      fields: [
        {
          key: "id",
          label: this.$t("table.id"),
          sortable: true,
          thStyle: { width: "70px" }
        },
        {
          key: "name",
          label: this.$t("users.user"),
          sortable: true
        },
        {
          key: "active",
          label: this.$t("table.status"),
          sortable: true
        },
        {
          key: "language",
          label: this.$t("table.language"),
          sortable: true
        },
        {
          key: "actions",
          label: this.$t("table.actions")
        }
      ]
    };
  },
  computed: {
    ...mapGetters("route", ["requestParams", "cancelToken"])
  },
  mounted() {
    this.loadUsers();
    this.$root.$on("usersTableRefresh", () => {
      this.refreshCard();
    });
  },
  methods: {
    keymonitor(event) {
      if (event.target.id === "searchUsers") {
        this.searchTitle = event.target.value;
        this.loadUsers();
      }
    },
    loadUsers() {
      let params = this.requestParams("users");
      let cancelToken = this.cancelToken();
      Users.getAll(params, { cancelToken })
        .then(response => {
          this.users = response.data.data;
          this.meta = response.data.meta;
        })
        .catch(error => {
          this.$error(error);
        });
    },
    closeCard() {
      $("#usersCard").fadeOut(300, function () {
        $("#usersCard").parent().remove();
      });
      this.$destroy();
    },
    refreshCard() {
      this.loadUsers();
    },
    editUser(id) {
      this.$refs.editUser.showModal(id);
    },
    createUser() {
      this.$router.push({ name: "projectSettingsUsersCreate" });
    },
    showDetails(id) {
      this.$router.push({
        name: "projectSettingsUsersAccessInformation",
        params: { id: id }
      });
    },
    deleteUser(id, name) {
      Swal.fire({
        title: this.$t("admin.adminDeleteTitle"),
        text: this.$t("admin.adminDeleteMessage", { user: name }),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("general.cancel"),
        confirmButtonText: this.$t("general.delete")
      }).then(result => {
        if (result.isConfirmed) {
          Users.delete(id)
            .then(() => {
              this.$toast.fire({
                icon: "success",
                title: this.$t("users.userDeleted")
              });

              if (this.users.length === 1 && this.page !== 1) {
                this.handlePageChange(this.page - 1);
              } else {
                this.refreshCard();
              }
            })
            .catch(error => {
              if (typeof error.response !== "undefined") {
                Swal.fire("Error", error.response.data.message, "error");
              }
            });
        }
      });
    }
  }
};
</script>
