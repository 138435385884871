<template>
  <div>
    <div v-if="clientSelected || appVersion >= 2" class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <MailTemplatesTable></MailTemplatesTable>
      </div>
    </div>
    <div v-if="!clientSelected && appVersion < 2" class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <b-alert variant="danger" show>
          {{ $t("general.selectClientFirst") }}
        </b-alert>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import MailTemplatesTable from "@/components/Projects/Settings/MailRecipients/Table";

export default {
  name: "ProjectsSettingsMailRecipientsIndex",
  components: {
    MailTemplatesTable
  },
  computed: {
    clientSelected: function () {
      return this.$store.getters.selectedClient !== null;
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.projectSettings"),
        route: ""
      },
      {
        title: this.$t("menu.projectSettingsMailTemplates"),
        route: ""
      },
      {
        title: this.$t("general.overview"),
        route: "/project/settings/mails"
      }
    ]);
  }
};
</script>
