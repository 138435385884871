<template>
  <b-row>
    <b-col md="12">
      <Table v-if="$route.name === 'projectSettingsPlugins'" />
      <Details v-if="$route.name === 'projectSettingsPlugins'" />
    </b-col>
  </b-row>
</template>

<script>
import Table from "@/components/Projects/Settings/Plugins/Table";
import Details from "@/components/Projects/Settings/Plugins/Details";

export default {
  name: "ProjectSettingsPluginIndex",
  components: {
    Table,
    Details
  }
};
</script>
