<template>
  <div id="projectsCard" class="card card-custom grid-stack-item-content">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">{{ $t("projects.title") }}</h3>
      </div>
      <div class="card-toolbar">
        <button class="btn btn-primary btn-hover" @click="createProject">
          <i class="fal fa-plus"></i>
          {{ $t("projects.projectCreate") }}
        </button>
      </div>
    </div>

    <v-progress-linear v-if="isBusy" indeterminate color="primary" />

    <div class="card-body column">
      <TableWrapper :meta="meta" @reload-data="loadProjects">
        <b-table
          responsive
          :items="projects"
          :fields="fields"
          class="dataTable table table-head-custom"
          :show-empty="true"
          :empty-text="$t('table.noRecords')"
        >
          <template #cell(name)="data">
            <div class="row flex-wrap py-3">
              <div class="col-auto symbol py-0">
                <div
                  class="symbol-label symbol-label-profile"
                  :style="
                    typeof data.item.image === 'object' &&
                    Object.keys(data.item.image).length
                      ? 'background-image: url(' +
                        'data:' +
                        data.item.image.mimeType +
                        ';base64,' +
                        data.item.image.base64 +
                        '); background-color: #ffffff;'
                      : ''
                  "
                ></div>
              </div>
              <div
                class="col-auto flex-grow-1 py-0"
                style="cursor: pointer"
                @click="showDetails(data.item.id)"
              >
                <span class="d-block">{{ data.item.name }}</span>
                <span class="text-muted">{{ data.item.short }}</span>
              </div>
            </div>
          </template>

          <template #cell(started_at)="data">
            <div class="row flex-wrap py-3">
              <div class="col-auto flex-grow-1 py-0">
                <span v-if="data.item.started_at" class="d-block">
                  {{ formatDateAssigned(data.item.started_at) }}
                </span>
                <span v-else class="d-block">-</span>
              </div>
            </div>
          </template>

          <template #cell(finished_at)="data">
            <div class="row flex-wrap py-3">
              <div class="col-auto flex-grow-1 py-0">
                <span v-if="data.item.finished_at" class="d-block">
                  {{ formatDateAssigned(data.item.finished_at) }}
                </span>
                <span v-else class="d-block">-</span>
              </div>
            </div>
          </template>

          <template #cell(actions)="data">
            <b
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="showDetails(data.item.id)"
            >
              <i class="fal fa-pen"></i>
            </b>
            <b
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="deleteProject(data.item)"
            >
              <i class="fal fa-trash"></i>
            </b>
          </template>
        </b-table>
      </TableWrapper>
    </div>
  </div>
</template>

<script>
import Projects from "@/components/Projects/Settings/Projects/project";
import { formatDate } from "@/components/Tools/modifiers";
import { bus } from "@/main";
import { mapGetters } from "vuex";
import TableWrapper from "@/components/Tools/TableWrapper";

export default {
  name: "ProjectsSettingsProjectsTable",
  components: { TableWrapper },
  data() {
    return {
      projects: [],
      searchTitle: "",
      fields: [
        {
          key: "id",
          label: this.$t("table.id"),
          sortable: true,
          thStyle: "width: 70px"
        },
        {
          key: "name",
          label: this.$t("table.project"),
          sortable: true
        },
        {
          key: "url",
          label: this.$t("table.url"),
          sortable: true
        },
        {
          key: "started_at",
          formatter: "formatDateAssigned",
          label: this.$t("table.startedAt"),
          sortable: true
        },
        {
          key: "finished_at",
          formatter: "formatDateAssigned",
          label: this.$t("table.finishedAt"),
          sortable: true
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          sortable: false,
          thStyle: { width: "170px" }
        }
      ],
      // Meta info
      meta: {},
      isBusy: false
    };
  },
  computed: {
    ...mapGetters(["selectedClient"]),
    ...mapGetters("route", ["requestParams", "cancelToken"])
  },
  watch: {
    selectedClient: function() {
      this.loadProjects();
    }
  },
  mounted() {
    this.loadProjects();
  },
  methods: {
    createProject() {
      this.$router.push({ name: "projectSettingsProjectsCreate" });
    },
    loadProjects() {
      if (!this.$store.getters.apiToken && this.appVersion < 2) {
        return;
      }
      if (this.selectedClient === null && this.appVersion < 2) {
        this.projects = [];
        return false;
      }

      this.isBusy = true;
      let params = this.requestParams();
      let cancelToken = this.cancelToken();

      Projects.getAll(params, "", cancelToken)
        .then(response => {
          this.projects = response.data.data;
          this.meta = response.data.meta;
          this.isBusy = false;
        })
        .catch(error => {
          this.$error(error);
          this.isBusy = this.axios.isCancel(error);
        });
    },
    formatDateAssigned(value) {
      return formatDate(value, "DD.MM.YYYY");
    },
    showDetails(id) {
      this.$router.push({
        name: "projectSettingsProjectsDetails",
        params: { projectId: id }
      });
    },
    deleteProject(project) {
      this.$swal
        .fire({
          title: this.$t("projects.removeProject", { name: project.name }),
          icon: "warning",
          showConfirmButton: true,
          confirmButtonText: this.$t("general.remove"),
          showCancelButton: true,
          reverseButtons: true,
          cancelButtonText: this.$t("general.cancel")
        })
        .then(swalResponse => {
          if (swalResponse.isConfirmed) {
            this.isBusy = true;
            Projects.delete(project.id)
              .then(() => {
                this.showDeletedToast(project.name);
                this.loadProjects();
                bus.$emit("reloadHeaderFilter");
              })
              .catch(error => {
                this.$swal.fire({
                  title: this.$t("general.caution"),
                  text: error.response.data.message,
                  icon: "error"
                });
                this.isBusy = false;
              });
          }
        });
    },
    showDeletedToast(name) {
      this.$toast.fire({
        icon: "success",
        title: this.$t("projects.deleted", { name: name })
      });
    }
  }
};
</script>

