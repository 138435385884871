var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-custom"},[_c('div',{staticClass:"card-header py-3"},[_c('div',{staticClass:"card-title d-flex align-items-center"},[_c('h3',{staticClass:"card-label font-weight-bolder text-dark"},[_vm._v(" "+_vm._s(_vm.$t("projects.informationProject"))+" ")])]),_c('div',{staticClass:"card-toolbar"},[_c('button',{staticClass:"btn btn-secondary mr-2",on:{"click":function($event){return _vm.$router.push({ name: 'projectSettingsProjects' })}}},[_vm._v(" "+_vm._s(_vm.$t("general.cancel"))+" ")]),_c('button',{staticClass:"btn",class:_vm.isValid ? 'btn-primary' : 'btn-secondary',on:{"click":_vm.updateProject}},[_vm._v(" "+_vm._s(_vm.$t("general.save"))+" ")])])]),(_vm.isBusy)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('form',{staticClass:"form"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group row align-items-center"},[_c('label',{staticClass:"col-xl-2 col-lg-2 col-md-3 col-form-label"},[_vm._v(" "+_vm._s(_vm.$t("projects.name"))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-md-9 col-lg-8 col-xl-8"},[_c('b-form-input',{staticClass:"form-control",class:!_vm.$v.project.name.required ||
              !_vm.$v.project.name.minLength ||
              !_vm.$v.project.name.maxLength
                ? 'is-invalid'
                : 'is-valid',attrs:{"type":"text"},on:{"input":_vm.validateFields},model:{value:(_vm.project.name),callback:function ($$v) {_vm.$set(_vm.project, "name", $$v)},expression:"project.name"}}),(!_vm.$v.project.name.required)?_c('b-form-invalid-feedback',{staticClass:"ml-1",attrs:{"id":"projectName-feedback"}},[_vm._v(" "+_vm._s(_vm.$t("validation.required.name"))+" ")]):_vm._e(),(!_vm.$v.project.name.minLength)?_c('b-form-invalid-feedback',{staticClass:"ml-1",attrs:{"id":"projectName-feedback"}},[_vm._v(" "+_vm._s(_vm.$t("validation.minChars", { chars: 3 }))+" ")]):_vm._e(),(!_vm.$v.project.name.maxLength)?_c('b-form-invalid-feedback',{staticClass:"ml-1",attrs:{"id":"projectName-feedback"}},[_vm._v(" "+_vm._s(_vm.$t("validation.maxChars", { chars: 255 }))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"form-group row align-items-center"},[_c('label',{staticClass:"col-xl-2 col-lg-2 col-md-3 col-form-label"},[_vm._v(" "+_vm._s(_vm.$t("projects.short"))+" ")]),_c('div',{staticClass:"col-md-9 col-lg-8 col-xl-8"},[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"text"},model:{value:(_vm.project.short),callback:function ($$v) {_vm.$set(_vm.project, "short", $$v)},expression:"project.short"}})],1)]),_c('div',{staticClass:"form-group row align-items-start"},[_c('label',{staticClass:"col-xl-2 col-lg-2 col-md-3 col-form-label"},[_vm._v(" "+_vm._s(_vm.$t("projects.description"))+" ")]),_c('div',{staticClass:"col-md-9 col-lg-8 col-xl-8"},[_c('quill-editor',{ref:"myTextEditor",staticClass:"editor",attrs:{"options":_vm.editorOptions,"value":_vm.project.description},on:{"change":_vm.onEditorChange}})],1)]),_c('div',{staticClass:"form-group row align-items-center"},[_c('label',{staticClass:"col-xl-2 col-lg-2 col-md-3 col-form-label"},[_vm._v(" "+_vm._s(_vm.$t("projects.startedAt"))+" ")]),_c('div',{staticClass:"col-md-9 col-lg-8 col-xl-8"},[_c('b-form-datepicker',{staticClass:"form-control",attrs:{"date-format-options":{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric'
            },"locale":"de","placeholder":_vm.$t('projects.noDateSelected'),"hide-header":true},on:{"input":_vm.validateFields},model:{value:(_vm.project.started_at),callback:function ($$v) {_vm.$set(_vm.project, "started_at", $$v)},expression:"project.started_at"}})],1)]),_c('div',{staticClass:"form-group row align-items-center"},[_c('label',{staticClass:"col-xl-2 col-lg-2 col-md-3 col-form-label"},[_vm._v(" "+_vm._s(_vm.$t("projects.finishedAt"))+" ")]),_c('div',{staticClass:"col-md-9 col-lg-8 col-xl-8"},[_c('b-form-datepicker',{staticClass:"form-control",class:!_vm.validations.dates ? 'is-invalid' : '',attrs:{"date-format-options":{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric'
            },"locale":"de","placeholder":_vm.$t('projects.noDateSelected'),"hide-header":true},on:{"input":_vm.validateFields},model:{value:(_vm.project.finished_at),callback:function ($$v) {_vm.$set(_vm.project, "finished_at", $$v)},expression:"project.finished_at"}}),(!_vm.validations.dates)?_c('b-form-invalid-feedback',{staticClass:"ml-1",attrs:{"id":"finishedAt-feedback"}},[_vm._v(" "+_vm._s(_vm.$t("validation.dates"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"form-group row align-items-center"},[_c('label',{staticClass:"col-xl-2 col-lg-2 col-md-3 col-form-label"},[_vm._v(" "+_vm._s(_vm.$t("projects.url"))+" ")]),_c('div',{staticClass:"col-md-9 col-lg-8 col-xl-8"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col pr-0"},[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"text"},on:{"input":_vm.validateFields},model:{value:(_vm.project.url),callback:function ($$v) {_vm.$set(_vm.project, "url", $$v)},expression:"project.url"}})],1),_c('div',{staticClass:"col-auto m-auto"},[_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":!_vm.isValidURL},on:{"click":function($event){$event.preventDefault();return _vm.getImageFromUrl.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("projects.loadImage"))+" ")])])])])]),_c('div',{staticClass:"form-group row align-items-start"},[_c('label',{staticClass:"col-xl-2 col-lg-2 col-md-3 col-form-label mt-1"},[_vm._v(" "+_vm._s(_vm.$t("projects.image"))+" ")]),_c('div',{staticClass:"col-md-9 col-lg-8 col-xl-8"},[_c('div',{staticClass:"image-input image-input-outline",attrs:{"id":"profile-image"}},[_c('div',{staticClass:"image-input-wrapper",style:(_vm.project.image
                  ? 'background-image: url(' +
                    _vm.project.image +
                    '); background-color: #ffffff; background-size: contain;'
                  : '')}),_c('label',{staticClass:"btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow",attrs:{"data-action":"change"},on:{"click":_vm.clickUpload}},[_c('i',{staticClass:"fa fa-pen icon-sm text-muted"})]),(_vm.project.image)?_c('span',{staticClass:"btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow",attrs:{"data-action":"remove"},on:{"click":function($event){_vm.project.image = ''}}},[_c('i',{staticClass:"ki ki-bold-close icon-xs text-muted"})]):_vm._e()]),_c('v-file-input',{ref:"imageInput",staticClass:"d-none",attrs:{"outlined":"","dense":"","hide-details":""},on:{"change":_vm.onFileChange},model:{value:(_vm.project.imageFile),callback:function ($$v) {_vm.$set(_vm.project, "imageFile", $$v)},expression:"project.imageFile"}})],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }