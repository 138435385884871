<template>
  <div>
    <div v-if="accessGranted">
      <div v-if="appVersion >= 2" class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <QueuesTable v-if="$route.name === 'projectSettingsQueues'" />
          <QueuesCreate
              v-else-if="$route.name === 'projectSettingsQueuesCreate'"
          />
          <QueuesDetails
              v-else-if="$route.name.includes('projectSettingsQueuesEdit')"
          />
        </div>
      </div>
      <div v-if="clientSelected && appVersion < 2" class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <QueuesTable v-if="$route.name === 'projectSettingsQueues'" />
          <QueuesCreate
            v-else-if="$route.name === 'projectSettingsQueuesCreate'"
          />
          <QueuesDetails
            v-else-if="$route.name.includes('projectSettingsQueuesEdit')"
          />
        </div>
      </div>
      <div v-if="!clientSelected && appVersion < 2" class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <b-alert variant="danger" show>
            {{ $t("general.selectClientFirst") }}
          </b-alert>
        </div>
      </div>
    </div>
    <div v-if="!accessGranted" class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <Denied></Denied>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import QueuesTable from "@/components/Projects/Settings/Queues/Table";
import QueuesCreate from "@/components/Projects/Settings/Queues/Create";
import QueuesDetails from "@/components/Projects/Settings/Queues/Edit";
import Denied from "@/components/SYS/errors/Denied";
import { bus } from "@/main";

export default {
  name: "ProjectsSettingsQueuesIndex",
  components: {
    QueuesTable,
    QueuesCreate,
    QueuesDetails,
    Denied
  },
  data() {
    return {
      accessGranted: true
    };
  },
  computed: {
    clientSelected: function() {
      return this.$store.getters.selectedClient;
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.projectSettings"),
        route: ""
      },
      {
        title: this.$t("menu.projectSettingsQueues"),
        route: ""
      },
      {
        title: this.$t("general.overview"),
        route: "/project/settings/queues"
      }
    ]);

    bus.$on("setQueuesGranted", this.setGranted);
  },
  destroyed() {
    bus.$off("setQueuesGranted", this.setGranted);
  },
  methods: {
    setGranted(value) {
      this.accessGranted = value;
    }
  }
};
</script>
