<template>
  <div class="card card-custom">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">{{ $t("plugins.plugins") }}</h3>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body column">
      <TableWrapper :meta="meta" @reload-data="loadPlugins">
        <b-table
          responsive
          :items="plugins"
          :fields="fields"
          class="dataTable table table-head-custom"
          :show-empty="true"
          :empty-text="$t('table.noRecords')"
        >
          <template #cell(isInstalled)="data">
            <span v-if="data.item.isInstalled">
              {{
                $t("plugins.statusInstalledBy", {
                  user: data.item.installed_by.name,
                  date: formatDateDMY(data.item.installed_at)
                })
              }}
            </span>
            <span v-else>{{ $t("plugins.statusNotInstalled") }}</span>
          </template>
          <template #cell(actions)="data">
            <button
              v-if="!data.item.isInstalled"
              v-b-tooltip.leftbottom="$t('plugins.installTooltip')"
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="installPlugin(data.item)"
            >
              <i class="fa fa-plus"></i>
            </button>
            <button
              v-else
              v-b-tooltip.leftbottom="$t('plugins.uninstallTooltip')"
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="uninstallPlugin(data.item)"
            >
              <i class="fa fa-minus"></i>
            </button>
            <button
              v-b-tooltip.leftbottom="$t('plugins.infoTooltip')"
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="showDetails(data.item)"
            >
              <i class="fal fa-info-circle"></i>
            </button>
          </template>
        </b-table>
      </TableWrapper>
    </div>
  </div>
</template>

<script>
import Plugins from "@/components/Admins/Settings/Plugins/plugins";
import TableWrapper from "@/components/Tools/TableWrapper";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import { formatDate } from "@/components/Tools/modifiers";

export default {
  name: "PluginsTable",
  components: { TableWrapper },
  data() {
    return {
      searchTitle: "",
      plugins: [],
      fields: [
        {
          key: "id",
          label: this.$t("table.id"),
          sortable: true
        },
        {
          key: "label",
          label: this.$t("plugins.label"),
          sortable: true
        },
        {
          key: "description",
          label: this.$t("plugins.description"),
          sortable: true
        },
        {
          key: "isInstalled",
          label: this.$t("plugins.status"),
          sortable: true
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          sortable: false,
          thStyle: { width: "150px" }
        }
      ],
      // Meta info
      meta: {},
      isBusy: false
    };
  },
  computed: {
    ...mapGetters("route", ["requestParams", "cancelToken"])
  },
  mounted() {
    this.loadPlugins();
  },
  methods: {
    loadPlugins() {
      this.isBusy = true;
      let params = this.requestParams();
      let cancelToken = this.cancelToken();
      Plugins.getAll(params, cancelToken)
        .then(response => {
          this.plugins = response.data.data;
          this.meta = response.data.meta;
          this.isBusy = false;
        })
        .catch(error => {
          this.$error(error);
          this.isBusy = this.axios.isCancel(error);
        });
    },
    installPlugin(plugin) {
      Swal.fire({
        title: this.$t("plugins.confirmInstallTitle"),
        text: this.$t("plugins.confirmInstallText", { plugin: plugin.label }),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-secondary"
        },
        closeButtonHtml: "<h3>test</h3>",
        confirmButtonText: this.$t("plugins.confirmInstallButton"),
        cancelButtonText: this.$t("general.cancel"),
        reverseButtons: true
      }).then(result => {
        if (result.isConfirmed) {
          this.isBusy = true;
          Plugins.install(plugin.id)
            .then(() => {
              this.isBusy = false;
              this.loadPlugins();
            })
            .catch(error => {
              this.isBusy = false;
              console.log(error);
            });
        }
        this.isBusy = false;
      });
    },
    uninstallPlugin(plugin) {
      Swal.fire({
        title: this.$t("plugins.confirmUninstallTitle"),
        text: this.$t("plugins.confirmUninstallText", { plugin: plugin.label }),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-secondary"
        },
        cancelButtonText: this.$t("general.cancel"),
        confirmButtonText: this.$t("plugins.confirmUninstallButton"),
        reverseButtons: true
      }).then(result => {
        if (result.isConfirmed) {
          this.isBusy = true;
          Plugins.uninstall(plugin.id)
            .then(() => {
              this.isBusy = false;
              this.loadPlugins();
            })
            .catch(error => {
              this.isBusy = false;
              console.log(error);
            });
        }
        this.isBusy = false;
      });
    },
    showDetails(plugin) {
      this.$router.push({
        name: "adminSettingsPluginsDetails",
        params: { plugin: plugin }
      });
    },
    formatDateDMY(date) {
      return formatDate(date, "DD.MM.YYYY");
    }
  }
};
</script>
