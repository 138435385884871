<template>
  <div class="card card-custom grid-stack-item-content">
    <div class="card-header" style="cursor: move">
      <div class="card-title">
        <h3 class="card-label">{{ $t("workflowToken.title") }}</h3>
      </div>
      <div class="card-toolbar">
        <button class="btn btn-primary btn-hover" @click="createToken">
          <i class="fal fa-plus"></i>
          {{ $t("workflowToken.create") }}
        </button>
      </div>
    </div>

    <v-progress-linear v-if="isBusy" indeterminate color="primary" />

    <div class="card-body column">
      <TableWrapper :meta="meta" @reload-data="loadToken">
        <b-table
          responsive
          :items="token"
          :fields="fields"
          class="dataTable table table-head-custom"
          :show-empty="true"
          :empty-text="$t('table.noRecords')"
        >
          <template #cell(workflows)="data">
            {{ getProcessNames(data.item) }}
          </template>
          <template #cell(actions)="data">
            <b
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="showDetails(data.item.id)"
            >
              <i class="fal fa-pen"></i>
            </b>
            <b
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="deleteToken(data.item)"
            >
              <i class="fal fa-trash"></i>
            </b>
          </template>
        </b-table>
      </TableWrapper>
    </div>
  </div>
</template>

<script>
import processToken from "@/components/Projects/Settings/WorkflowToken/processToken";
import TableWrapper from "@/components/Tools/TableWrapper";
import { mapGetters } from "vuex";

export default {
  components: { TableWrapper },
  data() {
    return {
      isBusy: false,
      token: [],
      fields: [
        {
          key: "label",
          label: this.$t("table.name")
        },
        {
          key: "value",
          label: this.$t("table.token")
        },
        {
          key: "workflows",
          label: this.$t("table.workflows")
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          sortable: false,
          thStyle: { width: "170px" }
        }
      ],
      searchTitle: "",

      // Meta info
      meta: {},
      totalPages: 1,
      totalRecords: 0,
    };
  },
  computed: {
    ...mapGetters("route", ["requestParams", "cancelToken"])
  },
  mounted() {
    this.loadToken();
  },
  methods: {
    setMeta(meta) {
      this.meta = meta;
      this.totalPages = meta.last_page;
      this.totalRecords = meta.total;
    },
    createToken() {
      this.$router.push({ name: "projectSettingsWorkflowTokenCreate" });
    },
    loadToken() {
      this.isBusy = true;
      let params = this.requestParams();
      let cancelToken = this.cancelToken();

      processToken
        .getAll(params, cancelToken)
        .then(response => {
          this.token = response.data.data;
          this.setMeta(response.data.meta);
          this.isBusy = false;
        })
        .catch(error => {
          this.$error(error);
          this.isBusy = this.axios.isCancel(error);
        });
    },
    getProcessNames(processToken) {
      let processNames = processToken.processes.map(p => p.name);
      return processNames.join(", ");
    },
    showDetails(id) {
      this.$router.push({
        name: "projectSettingsWorkflowTokenDetail",
        params: { tokenId: id }
      });
    },
    deleteToken(token) {
      this.$swal
        .fire({
          title: this.$t("workflowToken.remove", { name: token.label }),
          icon: "warning",
          showConfirmButton: true,
          confirmButtonText: this.$t("general.remove"),
          showCancelButton: true,
          reverseButtons: true,
          cancelButtonText: this.$t("general.cancel")
        })
        .then(swalResponse => {
          if (swalResponse.isConfirmed) {
            this.isBusy = true;
            processToken
              .delete(token.id)
              .then(() => {
                this.showDeletedToast(token.label);
                this.loadToken();
              })
              .catch(error => {
                this.$swal.fire({
                  title: this.$t("general.caution"),
                  text: error.response.data.message,
                  icon: "error"
                });
                this.isBusy = false;
              });
          }
        });
    },
    showDeletedToast(name) {
      this.$toast.fire({
        icon: "success",
        title: this.$t("workflowToken.deleted", { name: name })
      });
    }
  }
};
</script>

<style scoped></style>
