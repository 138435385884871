<template>
  <div class="card card-custom">
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("config.configUpdate") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-secondary mr-2"
          @click="$router.push({ name: 'projectSettingsConfig' })"
        >
          {{ $t("general.cancel") }}
        </button>
        <b-button
          v-show="saveButtonEnabled"
          variant="primary"
          @click="updateConfig"
        >
          {{ $t("general.save") }}
        </b-button>
        <b-button v-show="!saveButtonEnabled" variant="secondary">
          {{ $t("general.save") }}
        </b-button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <form ref="form" class="form" @submit="updateConfig">
      <div class="card-body">
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("config.name") }}
          </label>
          <div class="col-md-9 col-lg-4 col-xl-6">
            <b-form-input
              v-model="form.name"
              type="text"
              :placeholder="$t('config.nameEnter')"
              class="form-control"
              :class="!$v.form.name.required ? 'is-invalid' : 'is-valid'"
              required
              @input="validateFields"
            />
            <b-form-invalid-feedback v-if="!$v.form.name.required">
              {{ $t("validation.required.technicalName") }}
            </b-form-invalid-feedback>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("config.label") }}
          </label>
          <div class="col-md-9 col-lg-4 col-xl-6">
            <b-form-input
              id="label"
              v-model="form.label"
              :placeholder="$t('config.label')"
              class="form-control"
              :class="!$v.form.label.required ? 'is-invalid' : 'is-valid'"
              required
              @input="validateFields"
            >
            </b-form-input>
            <b-form-invalid-feedback v-if="!$v.form.label.required">
              {{ $t("validation.required.label") }}
            </b-form-invalid-feedback>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("config.type") }}
          </label>
          <div class="col-md-9 col-lg-4 col-xl-6">
            <v-select
              v-model="$v.form.type.$model"
              class="form-control"
              :class="!$v.form.type.required ? 'is-invalid' : 'is-valid'"
              :items="types"
              :menu-props="{ offsetY: true }"
              @change="typeChanged"
            />
          </div>
        </div>

        <div v-if="form.type === 'json' && typeof form.value === 'object'">
          <b-row>
            <b-col md="12">
              <table class="table table-head-custom" style="min-width: 100%">
                <thead>
                  <tr>
                    <th class="align-middle">{{ $t("config.key") }}</th>
                    <th class="align-middle">{{ $t("config.value") }}</th>
                    <th class="align-middle">
                      <b
                        class="btn btn-icon btn-light btn-sm mr-1"
                        @click="addRow"
                      >
                        <i class="fal fa-plus"></i>
                      </b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(entry, key) in form.value"
                    :key="key"
                    class="jsonRows"
                  >
                    <td>
                      <div class="form-group row align-items-center">
                        <div class="col-md-12 col-lg-12 col-xl-12">
                          <b-form-input
                            class="formKeys form-control"
                            :value="key"
                            :placeholder="$t('config.key')"
                            required
                            @change="keyUpdated(key, $event)"
                          >
                          </b-form-input>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="form-group row align-items-center">
                        <div class="col-md-12 col-lg-12 col-xl-12">
                          <b-form-input
                            v-model="form.value[key]"
                            class="formValues form-control"
                            :value="entry"
                            :placeholder="$t('config.value')"
                          >
                          </b-form-input>
                        </div>
                      </div>
                    </td>
                    <td>
                      <b
                        class="btn btn-icon btn-light btn-sm mr-1"
                        @click="deleteRow(key)"
                      >
                        <i class="fal fa-trash"></i>
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
        </div>

        <div v-if="form.type !== 'json'">
          <div class="form-group row align-items-center">
            <label class="col-xl-3 col-lg-3 col-form-label">
              {{ $t("config.value") }}
            </label>
            <div class="col-md-9 col-lg-4 col-xl-6">
              <b-form-input
                v-model="form.value"
                :type="form.type"
                :placeholder="$t('config.value')"
                class="form-control"
                :class="!$v.form.value.required ? 'is-invalid' : 'is-valid'"
                required
                @input="validateFields"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import $ from "jquery";
import Swal from "sweetalert2";

import Config from "@/components/Projects/Settings/Config/config";
import { required } from "vuelidate/lib/validators";

export default {
  validations: {
    form: {
      name: { required },
      label: { required },
      type: { required },
      value: { required }
    }
  },
  name: "ProjectsSettingsConfigEdit",
  data() {
    return {
      configId: false,
      saveButtonEnabled: false,
      isBusy: false,
      form: {
        name: "",
        label: "",
        type: "text",
        value: ""
      },
      types: [
        {
          text: "json",
          value: "json"
        },
        {
          text: "text",
          value: "text"
        },
        {
          text: "number",
          value: "number"
        }
      ]
    };
  },
  mounted() {
    this.configId = this.$route.params.configId;
    this.loadConfig();
  },
  methods: {
    deleteRow(key) {
      delete this.form.value[key];
      this.$forceUpdate();
    },
    addRow() {
      this.form.value[""] = "";
      this.$forceUpdate();
    },
    keyUpdated(oldKey, newKey) {
      this.form.value[newKey] = this.form.value[oldKey];
      delete this.form.value[oldKey];
      this.$forceUpdate();
    },
    typeChanged() {
      if (this.form.type === "json" && typeof this.form.value !== "object") {
        this.form.value = {};
        this.addRow();
      }
      if (this.form.type === "text" && typeof this.form.value === "object") {
        this.form.value = "";
      }
      if (this.form.type === "number" && isNaN(this.form.value)) {
        this.form.value = "";
      }
      this.$forceUpdate();
      this.validateFields();
    },
    loadConfig() {
      Config.get(this.configId)
        .then(response => {
          this.form = {
            name: response.data.data.name,
            label: response.data.data.label,
            type: response.data.data.type,
            value: response.data.data.value
          };
          this.validateFields();
        })
        .catch(error => {
          Swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
        });
    },
    updateConfig() {
      Config.update(this.configId, this.form)
        .then(() => {
          this.$toast.fire({
            icon: "success",
            title: this.$t("config.configUpdated")
          });
          this.$router.push({ name: "projectSettingsConfig" });
        })
        .catch(error => {
          Swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          $.each(error.response.data.errors, function (fieldName, errors) {
            var fieldElement = $("#" + fieldName);
            fieldElement.addClass("is-invalid");
            fieldElement.next().text(errors[0]);
            fieldElement.on("keyup", function () {
              fieldElement.removeClass("is-invalid");
            });
          });
        });
    },
    resetForm() {
      this.form = {
        name: "",
        label: "",
        type: "text",
        value: ""
      };
    },
    validateFields() {
      this.$v.$touch();
      this.saveButtonEnabled = !this.$v.$invalid;
    }
  }
};
</script>
