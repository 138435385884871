<template>
  <div>
    <b-button
      pill
      variant="outline-primary"
      class="float-right"
      @click="showModal"
    >
      {{ $t("mailRecipient.recipientCreate") }}
    </b-button>

    <b-modal
      ref="createMailRecipientModal"
      hide-footer
      :title="$t('mailRecipient.recipientCreate')"
    >
      <b-form v-if="show" ref="form" @submit="onSubmit">
        <div class="d-block text-center">
          <b-form-group
            :label="$t('mailRecipient.recipient') + ':'"
            label-for="recipient"
          >
            <b-form-input
              id="recipient"
              v-model="form.recipient"
              required
              :placeholder="$t('mailRecipient.recipient')"
            >
            </b-form-input>
            <div class="invalid-feedback"></div>
          </b-form-group>
          <div class="row">
            <div class="col">
              <b-form-group
                :label="$t('mailRecipient.users')"
                label-for="users"
              >
                <b-form-select
                  id="users"
                  v-model="form.recipient"
                  :options="availableUsersOptions"
                >
                </b-form-select>
              </b-form-group>
            </div>
            <div v-if="userType === 'admin'" class="col-md-6">
              <b-form-group
                :label="$t('mailRecipient.admins')"
                label-for="admins"
              >
                <b-form-select
                  id="admins"
                  v-model="form.recipient"
                  :options="availableAdminsOptions"
                >
                </b-form-select>
              </b-form-group>
            </div>
          </div>
          <b-form-group
            :label="$t('mailRecipient.process')"
            label-for="process"
          >
            <b-form-select
              id="process_id"
              v-model="form.process_id"
              :options="processesOptions"
            >
            </b-form-select>
            <div class="invalid-feedback"></div>
          </b-form-group>
          <b-form-group
            :label="$t('mailRecipient.emailClass')"
            label-for="mailable_classname"
          >
            <b-form-select
              id="mailable_classname"
              v-model="form.mailable_classname"
              :options="mailTemplatesOptions"
              required
            >
            </b-form-select>
            <div class="invalid-feedback"></div>
          </b-form-group>
        </div>
        <b-button type="submit" class="mt-3" variant="outline-success" block>
          {{ $t("general.save") }}
        </b-button>
        <b-button
          type="button"
          class="mt-3"
          variant="outline-danger"
          block
          @click="hideModal"
        >
          {{ $t("general.cancel") }}
        </b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import MailRecipients from "@/components/Projects/Settings/MailRecipients/mailRecipients";
import ProcessManager from "@/components/Tenants/ProcessManager/processManager";
import MailTemplates from "@/components/Admins/Settings/Email/MailTemplates/mailTemplates";
import Users from "@/components/Tenants/Users/users";
import Admins from "@/components/Admins/Account/Admin/admins";

import $ from "jquery";
import Swal from "sweetalert2";
import { bus } from "@/main";

export default {
  name: "ProjectsSettingsMailRecipientsCreate",
  data() {
    return {
      userType: "",
      processesOptions: [],
      mailTemplatesOptions: [],
      availableUsersOptions: [],
      availableAdminsOptions: [],
      form: {},
      show: true
    };
  },
  mounted() {
    this.userType = this.$store.getters.userType;
    this.initializeData();
  },
  methods: {
    async showModal() {
      bus.$emit("hideTransparentLoader");
      this.$refs["createMailRecipientModal"].show();
    },
    hideModal() {
      this.$refs["createMailRecipientModal"].hide();
    },
    onSubmit(evt) {
      evt.preventDefault();
      bus.$emit("showTransparentLoader");
      MailRecipients.store(this.form)
        .then(() => {
          bus.$emit("hideTransparentLoader");
          this.$toast.fire({
            title: this.$t("mailRecipient.recipientCreated"),
            icon: "success"
          });
          this.hideModal();
          this.resetForm();
          this.$root.$emit("mailRecipientsTableRefresh", "");
        })
        .catch(error => {
          bus.$emit("hideTransparentLoader");
          Swal.fire({
            title: this.$t("general.caution"),
            text: this.$t("validation.pleaseCorrectData"),
            icon: "error"
          });
          let self = this;
          $.each(error.response.data.errors, function (fieldName, errors) {
            var fieldElement = $("#" + fieldName);
            var errorMessage = self.$t(errors[0] + "." + fieldName);
            fieldElement.addClass("is-invalid");
            fieldElement.next().text(errorMessage);
            fieldElement.on("keyup", function () {
              fieldElement.removeClass("is-invalid");
            });
          });
        });
    },
    resetForm() {
      this.form = {};
    },
    initializeData() {
      let processesOptions = [];
      let mailTemplatesOptions = [];
      let availableUsersOptions = [];
      let availableAdminsOptions = [];

      //Get Processes List
      let params = {
        title: "",
        page: 1,
        size: 50
      };
      ProcessManager.getAll(params, true)
        .then(response => {
          $.each(response.data.data, function (n, data) {
            let tmpObj = {
              text: data.name,
              value: data.id
            };
            processesOptions.push(tmpObj);
          });
        })
        .catch(() => {});
      this.processesOptions = processesOptions;
      //Get Processes List END
      //Get Mail Templates List
      MailTemplates.getAll(params)
        .then(response => {
          $.each(response.data.data, function (n, data) {
            let tmpObj = {
              text: data.mailable_classname + " (" + data.label + ")",
              value: data.mailable_classname
            };
            mailTemplatesOptions.push(tmpObj);
          });
        })
        .catch(() => {});
      this.mailTemplatesOptions = mailTemplatesOptions;
      //Get Mail Templates END
      //Get Available Users List
      Users.getAll(params)
        .then(response => {
          $.each(response.data.data, function (n, data) {
            let tmpObj = {
              text: data.name + " (" + data.email + ")",
              value: data.email
            };
            availableUsersOptions.push(tmpObj);
          });
        })
        .catch(() => {});
      this.availableUsersOptions = availableUsersOptions;
      //Get Available Users END

      //Get Available Users List

      if (this.userType === "admin") {
        Admins.getAll(params)
          .then(response => {
            $.each(response.data.data, function (n, data) {
              let tmpObj = {
                text: data.name + " (" + data.email + ")",
                value: data.email
              };
              availableAdminsOptions.push(tmpObj);
            });
          })
          .catch(() => {});
      }

      this.availableAdminsOptions = availableAdminsOptions;
      //Get Available Admins END
    }
  }
};
</script>
