<template>
  <div>
    <div
      id="mailRecipientsCard"
      class="card card-custom grid-stack-item-content"
    >
      <div class="card-header" style="cursor: move">
        <div class="card-title">
          <h3 class="card-label">{{ $t("mailRecipient.mailRecipient") }}</h3>
        </div>
        <div class="card-toolbar">
          <createMailRecipient></createMailRecipient>
          <editMailRecipient ref="editMailRecipient"></editMailRecipient>
          <a
            href="#"
            class="btn btn-icon btn-sm btn-hover-light-primary mr-1"
            data-card-tool="toggle"
            data-toggle="tooltip"
            data-placement="top"
            title="Toggle Card"
          >
            <i class="ki ki-arrow-down icon-nm"></i>
          </a>
          <a
            href="#"
            class="btn btn-icon btn-sm btn-hover-light-primary mr-1"
            data-card-tool="reload"
            data-toggle="tooltip"
            data-placement="top"
            :title="$t('general.reload')"
            @click="refreshCard($event)"
          >
            <i class="fal fa-arrows-rotate"></i>
          </a>
          <a
            href="#"
            class="btn btn-icon btn-sm btn-hover-light-primary"
            data-card-tool="remove"
            data-toggle="tooltip"
            data-placement="top"
            :title="$t('general.remove')"
            @click="closeCard($event)"
          >
            <i class="fal fa-xmark"></i>
          </a>
        </div>
      </div>
      <div class="card-body">
        <div id="mailRecipientsTable">
          <TableWrapper :meta="mailRecipientsMeta" @reload-data="loadMailRecipients">
            <b-table
                responsive
                :items="mailRecipients"
                :fields="fields"
                class="dataTable table table-head-custom"
                :show-empty="true"
                :empty-text="$t('table.noRecords')"
            >
              <template #cell(actions)="data">
                <div class="d-flex flex-row">
                  <b
                      class="btn btn-icon btn-light btn-sm mr-1 p-2"
                      @click="editMailRecipient(data.item.id)"
                  >
                    <i class="fal fa-pen"></i>
                  </b>
                  <b
                      class="btn btn-icon btn-light btn-sm mr-1 p-2"
                      @click="deleteMailRecipient(data.item.id, data.item.recipient)"
                  >
                    <i class="fal fa-trash"></i>
                  </b>
                </div>
              </template>
            </b-table>
          </TableWrapper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Swal from "sweetalert2";

import MailRecipients from "@/components/Projects/Settings/MailRecipients/mailRecipients";
import { bus } from "@/main";
import createMailRecipient from "@/components/Projects/Settings/MailRecipients/Create";
import editMailRecipient from "@/components/Projects/Settings/MailRecipients/Edit";
import { mapGetters } from "vuex";
import TableWrapper from "@/components/Tools/TableWrapper";

export default {
  name: "ProjectsSettingsMailRecipientsTable",
  components: {
    createMailRecipient,
    editMailRecipient,
    TableWrapper
  },
  data() {
    return {
      isBusy: false,
      mailRecipients: [],
      searchTitle: "",
      fields: [
        {
          key: "id",
          label: "#ID",
          sortable: true,
          thStyle: { width: "70px" }
        },
        {
          key: "recipient",
          label: this.$t("mailRecipient.recipient"),
          sortable: true,
          thStyle: { width: "180px" }
        },
        {
          key: "mailable_classname",
          label: this.$t("mailRecipient.emailClass"),
          sortable: true,
          thStyle: { width: "280px" }
        },
        {
          key: "process.name",
          label: this.$t("mailRecipient.process"),
          sortable: true,
          thStyle: { width: "280px" }
        },
        {
          key: "updated_at",
          formatter: "formatDateAssigned",
          label: this.$t("table.updatedAt"),
          sortable: true,
          thStyle: { width: "180px" }
        },
        {
          key: "created_at",
          formatter: "formatDateAssigned",
          label: this.$t("table.createdAt"),
          sortable: true,
          thStyle: { width: "180px" }
        },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "0px" }
        }
      ],
      mailRecipientsMeta: {},
      page: 1,
      totalPages: 10,
      pageSize: 10,
      pageSizes: [10, 20, 50, 100],
      totalRecords: 0
    };
  },
  computed: {
    ...mapGetters(["selectedClient"])
  },
  watch: {
    selectedClient: function () {
      this.loadMailRecipients();
    }
  },
  mounted() {
    this.loadMailRecipients();
    this.$root.$on("mailRecipientsTableRefresh", () => {
      this.refreshCard();
    });
  },
  methods: {
    keymonitor(event) {
      if (event.target.id === "searchMailRecipients") {
        this.searchTitle = event.target.value;
        this.loadMailRecipients();
      }
    },
    getRequestParams(searchTitle, page, pageSize) {
      let params = {};

      if (searchTitle) {
        params["title"] = searchTitle;
      }

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["size"] = pageSize;
      }

      return params;
    },
    handlePageChange(value) {
      this.page = value;
      this.loadMailRecipients();
    },
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.loadMailRecipients();
    },
    formatDateAssigned(value) {
      if (value === null) {
        return "";
      }
      var returnData = "";
      const dtm = value.split(" ");
      const date = dtm[0].split("-");
      returnData = date[2] + "." + date[1] + "." + date[0];
      if (typeof dtm[1] !== "undefined") {
        returnData += " - " + dtm[1];
      }
      return returnData;
    },
    loadMailRecipients() {
      //Check if client is selected before send the request
      if (this.selectedClient === null && this.appVersion < 2) {
        return false;
      }

      this.isBusy = true;
      const params = this.getRequestParams(
        this.searchTitle,
        this.page,
        this.pageSize
      );
      MailRecipients.getAll(params)
        .then(response => {
          const mailRecipients = response.data.data;
          const totalPages = response.data.meta.last_page;
          const totalRecords = response.data.meta.total;
          this.mailRecipientsMeta = response.data.meta;
          this.mailRecipients = mailRecipients;
          this.totalPages = totalPages;
          this.totalRecords = totalRecords;
          this.isBusy = false;
        })
        .catch(error => {
          this.mailRecipients = [];
          this.isBusy = false;
          Swal.fire("Achtung", error.response.data.message, "error");
        });
    },

    closeCard() {
      $("#mailRecipientsCard").fadeOut(300, function () {
        $("#mailRecipientsCard").parent().remove();
      });
      this.$destroy();
    },
    refreshCard() {
      this.loadMailRecipients();
    },
    editMailRecipient(id) {
      bus.$emit("showTransparentLoader");
      this.$refs.editMailRecipient.showModal(id);
    },
    deleteMailRecipient(id, name) {
      Swal.fire({
        title: this.$t("mailRecipient.deleteConfirm", { recipient: name }),
        text: this.$t("mailRecipient.deleteConfirmText", { recipient: name }),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("general.cancel"),
        confirmButtonText: this.$t("general.delete")
      }).then(result => {
        if (result.isConfirmed) {
          MailRecipients.delete(id)
            .then(() => {
              this.$toast.fire({
                title: this.$t("mailRecipient.deleteSucessText", {
                  recipient: name
                }),
                icon: "success"
              });
              if (this.mailRecipients.length === 1 && this.page !== 1) {
                this.handlePageChange(this.page - 1);
              } else {
                this.refreshCard();
              }
            })
            .catch(error => {
              Swal.fire("Error", error.response.data.message, "error");
              this.refreshCard();
            });
        }
      });
    }
  }
};
</script>
