<template>
  <div>
    <b-button
      pill
      variant="outline-primary"
      class="float-right"
      @click="showModal"
    >
      {{ $t("role.roleCreate") }}
    </b-button>
    <b-modal ref="createRoleModal" hide-footer :title="$t('role.roleCreate')">
      <b-form v-if="show" ref="form" @submit="onSubmit">
        <div class="d-block text-center">
          <b-form-group :label="$t('role.name')" label-for="name">
            <b-form-input
              id="name"
              v-model="form.name"
              required
              :placeholder="$t('role.name')"
            >
            </b-form-input>
            <div class="invalid-feedback text-left"></div>
          </b-form-group>
          <b-form-group
            :label="$t('role.nameDisplay')"
            label-for="display_name"
          >
            <b-form-input
              id="display_name"
              v-model="form.display_name"
              :placeholder="$t('role.nameDisplay')"
              required
            >
            </b-form-input>
            <div class="invalid-feedback text-left"></div>
          </b-form-group>
          <b-form-group
            id="input-group-3"
            :label="$t('role.description')"
            label-for="description"
          >
            <b-form-input
              id="description"
              v-model="form.description"
              :placeholder="$t('role.description')"
            >
            </b-form-input>
            <div class="invalid-feedback"></div>
          </b-form-group>
        </div>
        <b-button type="submit" class="mt-3" variant="outline-success" block>
          {{ $t("general.save") }}
        </b-button>
        <b-button
          type="button"
          class="mt-3"
          variant="outline-danger"
          block
          @click="hideModal"
        >
          {{ $t("general.cancel") }}
        </b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import Roles from "@/components/Admins/Roles/roles";
import $ from "jquery";
import Swal from "sweetalert2";

export default {
  name: "AdminsRolesCreate",
  data() {
    return {
      form: {
        name: "",
        display_name: "",
        description: ""
      },
      show: true
    };
  },
  methods: {
    showModal() {
      this.$refs["createRoleModal"].show();
    },
    hideModal() {
      this.$refs["createRoleModal"].hide();
    },
    onSubmit(evt) {
      evt.preventDefault();

      Roles.store(this.form)
        .then(() => {
          this.$toast.fire({
            title: this.$t("role.roleCreated"),
            icon: "success"
          });
          this.hideModal();
          this.resetForm();
          this.$root.$emit("rolesTableRefresh", "");
        })
        .catch(error => {
          Swal.fire({
            title: this.$t("general.caution"),
            text: this.$t("validation.pleaseCorrectData"),
            icon: "error"
          });
          let self = this;
          $.each(error.response.data.errors, function (fieldName, errors) {
            var fieldElement = $("#" + fieldName);
            var errorMessage = self.$t(errors[0] + "." + fieldName);
            fieldElement.addClass("is-invalid");
            fieldElement.next().text(errorMessage);
            fieldElement.on("keyup", function () {
              fieldElement.removeClass("is-invalid");
            });
          });
        });
    },
    resetForm() {
      this.form = {
        name: "",
        display_name: "",
        description: ""
      };
    }
  }
};
</script>
