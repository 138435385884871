var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-custom grid-stack-item-content",attrs:{"id":"usersCard"}},[_c('div',{staticClass:"card-header",staticStyle:{"cursor":"move"}},[_c('div',{staticClass:"card-title"},[_c('h3',{staticClass:"card-label"},[_vm._v(_vm._s(_vm.$t("users.usersManagement")))])]),_c('div',{staticClass:"card-toolbar"},[_c('button',{staticClass:"btn btn-primary btn-hover",on:{"click":_vm.createUser}},[_c('i',{staticClass:"fal fa-plus"}),_vm._v(" "+_vm._s(_vm.$t("users.userCreate"))+" ")])])]),(_vm.isBusy)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('div',{staticClass:"card-body column"},[_c('TableWrapper',{attrs:{"meta":_vm.meta,"param-prefix":"users"},on:{"reload-data":_vm.loadUsers}},[_c('b-table',{staticClass:"dataTable table table-head-custom",attrs:{"responsive":"","items":_vm.users,"fields":_vm.fields,"show-empty":true,"empty-text":_vm.$t('table.noRecords')},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(data){return [_c('b',{staticClass:"btn btn-icon btn-light btn-sm mr-1",on:{"click":function($event){return _vm.showDetails(data.item.id)}}},[_c('i',{staticClass:"fal fa-pen"})]),_c('b',{staticClass:"btn btn-icon btn-light btn-sm mr-1",on:{"click":function($event){return _vm.deleteUser(data.item.id, data.item.name)}}},[_c('i',{staticClass:"fal fa-trash"})])]}},{key:"cell(name)",fn:function(data){return [_c('div',{staticClass:"row flex-wrap py-3"},[_c('div',{staticClass:"col-auto symbol symbol-50 py-0"},[_c('div',{staticClass:"symbol-label symbol-label-profile",style:(data.item.profile_picture && data.item.profile_picture.length !== 0
                    ? 'background-image: url(data:' +
                      data.item.profile_picture.mimeType +
                      ';base64,' +
                      data.item.profile_picture.base64 +
                      '); background-color: #ffffff;'
                    : '')})]),_c('div',{staticClass:"col-auto flex-grow-1 py-0"},[_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(data.item.name))]),_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(data.item.email))])])])]}},{key:"cell(active)",fn:function(data){return [(data.item.active)?_c('span',{staticClass:"label label-lg label-light-success label-inline"},[_vm._v(" "+_vm._s(_vm.$t("general.active"))+" ")]):_c('span',{staticClass:"label label-lg label-light-danger label-inline"},[_vm._v(" "+_vm._s(_vm.$t("general.inactive"))+" ")])]}},{key:"cell(language)",fn:function(data){return [(data.item.language)?_c('span',[_vm._v(" "+_vm._s(data.item.language.label)+" ")]):_vm._e()]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }