<template>
  <div class="d-flex flex-row">
    <div
      id="kt_profile_aside"
      class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
    >
      <div class="card card-custom">
        <div class="card-body pt-4">
          <div class="d-flex justify-content-end"></div>
          <div class="row py-3">
            <div class="col-md-8 col-12 row align-items-center">
              <div class="col-12">
                <div class="font-weight-bolder font-size-h5 text-dark-75">
                  <span>{{ $t("menu.projectSettings") }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="navi navi-bold navi-hover navi-active navi-link-rounded">
            <div
              v-for="(item, i) in tabNavigation"
              :key="i"
              class="navi-item mb-2"
            >
              <router-link
                v-slot="{ href, navigate, isActive, isExactActive }"
                :to="{ name: item.route }"
                custom
              >
                <a
                  :href="href"
                  class="navi-link py-4"
                  :class="{
                    active: isExactActive || $route.name.includes(item.route)
                  }"
                >
                  <span v-if="item.icon" class="navi-icon">
                    <v-icon>{{ item.icon }}</v-icon>
                  </span>
                  <span class="navi-text">{{ $t(item.label) }}</span>
                </a>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex-row-fluid ml-lg-8 client-details">
      <projects v-if="$route.name.includes('projectSettingsProjects')" />
      <queues v-else-if="$route.name.includes('projectSettingsQueues')" />
      <mail-recipients v-else-if="$route.name === 'projectSettingsMails'" />
      <mail-templates v-else-if="$route.name === 'projectSettingsEmail'" />
      <mail-templates-details v-else-if="$route.name === 'projectSettingsEmailDetails'" />
      <configurations
        v-else-if="$route.name.includes('projectSettingsConfig')"
      />
      <workflow-token
        v-else-if="$route.name.includes('projectSettingsWorkflowToken')"
      />
      <basicSettings
          v-else-if="$route.name.includes('projectSettingsBasic')"
      />
      <usersManagement
          v-else-if="$route.name.includes('projectSettingsUsers')"
      />
      <loginSecurity
          v-else-if="$route.name.includes('projectSettingsLoginSecurity')"
      />
      <plugins
          v-else-if="$route.name.includes('projectSettingsPlugins')"
      />

      <!--
      <changeBatches
        v-else-if="$route.name === 'projectSettingsChangeBatches'"
      />
      -->
      <wip v-else />
    </div>
  </div>
</template>

<script>
import projects from "@/components/Projects/Settings/Projects/Index";
import queues from "@/components/Projects/Settings/Queues/Index";
import mailRecipients from "@/components/Projects/Settings/MailRecipients/Index";
import configurations from "@/components/Projects/Settings/Config/Index";
import workflowToken from "@/components/Projects/Settings/WorkflowToken/Index";
import basicSettings from "@/components/Projects/Settings/BasicSettings/Index";
import usersManagement from "@/components/Projects/Settings/Users/Index";
import loginSecurity from "@/components/Projects/Settings/LoginSecurity/Index";
import plugins from "@/components/Projects/Settings/Plugins/Index";
import mailTemplates from "@/components/Projects/Settings/MailTemplates/Index";
import mailTemplatesDetails from "@/components/Projects/Settings/MailTemplates/Details";
// import changeBatches from "@/components/Projects/Settings/ChangeBatches/Index";
import wip from "@/components/SYS/WIP";

export default {
  components: {
    projects,
    queues,
    mailRecipients,
    mailTemplates,
    mailTemplatesDetails,
    configurations,
    workflowToken,
    basicSettings,
    usersManagement,
    loginSecurity,
    plugins,
    // changeBatches,
    wip
  },
  data() {
    return {
      tabNavigation: [
        {
          route: "projectSettingsProjects",
          label: "menu.projectSettingsProjects",
          icon: "fal fa-tasks"
        },
        {
          route: "projectSettingsQueues",
          label: "menu.projectSettingsQueues",
          icon: "fal fa-indent"
        },
        {
          route: "projectSettingsMails",
          label: "menu.projectSettingsMailTemplates",
          icon: "fal fa-mail-bulk"
        },
        {
          route: "projectSettingsConfig",
          label: "menu.projectSettingsConfiguration",
          icon: "fal fa-file-alt"
        },
        {
          route: "projectSettingsWorkflowToken",
          label: "menu.projectSettingsWorkflowToken",
          icon: "fal fa-key"
        },
          /*
        {
          route: "projectSettingsChangeBatches",
          label: "menu.projectSettingsChangeBatches",
          icon: "fal fa-bookmark icon-lg"
        }
           */
      ]
    };
  },
  mounted() {
    if (this.appVersion >= 2) {
      this.tabNavigation.push({
        route: "projectSettingsBasic",
        label: "settings.baseSettings",
        icon: "fal fa-gear"
      })
      this.tabNavigation.push({
        route: "projectSettingsUsers",
        label: "users.usersManagement",
        icon: "fal fa-users"
      })
      this.tabNavigation.push({
        route: "projectSettingsLoginSecurity",
        label: "settings.loginSecurity",
        icon: "fal fa-shield"
      })
      this.tabNavigation.push({
        route: "projectSettingsPlugins",
        label: "menu.adminSettingsPlugins",
        icon: "fal fa-plug"
      })
      this.tabNavigation.push({
        route: "projectSettingsEmail",
        label: "menu.adminSettingsEmail",
        icon: "fal fa-user-gear"
      })

    }
    if (this.$route.name === "projectSettings") {
      this.$router.push({ name: "projectSettingsProjects" });
    }
  }
};
</script>
