import ApiService from "@/core/services/api.service";
var url = process.env.VUE_APP_API_ADMIN;

class Plugins {
  get(id) {
    return ApiService.get(url, "plugins/" + id);
  }
  getAll(params, cancelToken) {
    let options = new URLSearchParams(params).toString();
    let headers = {};
    if (cancelToken) {
      headers.cancelToken = cancelToken;
    }
    return ApiService.get(url, "plugins?" + options, headers);
  }
  store(data) {
    return ApiService.post(url + "/plugins", data);
  }
  update(id, data) {
    return ApiService.put(url + "/plugins/" + id, data);
  }
  delete(id) {
    return ApiService.delete(url + "/plugins/" + id);
  }
  install(id) {
    return ApiService.post(url + "/plugins/" + id + "/install");
  }
  uninstall(id) {
    return ApiService.post(url + "/plugins/" + id + "/uninstall");
  }
}
export default new Plugins();
