<template>
  <div>
    <Table v-if="$route.name === 'projectSettingsWorkflowToken'" />
    <Edit v-else />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Table from "@/components/Projects/Settings/WorkflowToken/Table";
import Edit from "@/components/Projects/Settings/WorkflowToken/Edit";

export default {
  name: "ProjectsSettingsWorkflowTokenIndex",
  components: {
    Table,
    Edit
  },
  data() {
    return {
      clientSelected: false,
      isBusy: false
    };
  },
  watch: {
    $route: function () {
      this.setBreadcrumbs();
    }
  },
  mounted() {
    this.setBreadcrumbs();
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.$t("menu.projectSettings"),
          route: ""
        },
        {
          title: this.$t("menu.projectSettingsWorkflowToken"),
          route: ""
        },
        {
          title: this.$t("general.overview"),
          route: "/project/settings/workflowToken"
        }
      ]);
    }
  }
};
</script>
