<template>
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div v-if="showTitle" class="card-title d-flex align-items-center">
        <h3 v-if="edit" class="card-label font-weight-bolder text-dark">
          {{ $t("workflowToken.details") }}
        </h3>
        <h3 v-else class="card-label font-weight-bolder text-dark">
          {{ $t("workflowToken.createTitle") }}
        </h3>
      </div>
      <div v-else>
        <v-spacer />
      </div>
      <div class="card-toolbar">
        <button
          v-if="showCancelButton"
          class="btn btn-secondary mr-2"
          @click="cancel"
        >
          {{ $t("general.cancel") }}
        </button>
        <button
          :class="isValid ? 'btn-primary' : 'btn-secondary'"
          class="btn"
          @click="saveToken"
        >
          {{ $t("general.save") }}
        </button>
      </div>
    </div>

    <v-progress-linear v-if="isBusy" indeterminate color="primary" />

    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="form-group row align-items-center">
          <label class="col-xl-2 col-lg-2 col-md-3 col-form-label">
            {{ $t("workflowToken.name") }} <span class="text-danger">*</span>
          </label>
          <div class="col-md-9 col-lg-10 col-xl-9">
            <b-form-input
              v-model="token.label"
              type="text"
              class="form-control"
              :class="
                !$v.token.label.required ||
                !$v.token.label.minLength ||
                !$v.token.label.maxLength
                  ? 'is-invalid'
                  : 'is-valid'
              "
              @input="validateFields"
            />
            <b-form-invalid-feedback
              v-if="!$v.token.label.required"
              class="ml-1"
            >
              {{ $t("validation.required.name") }}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              v-else-if="!$v.token.label.minLength"
              class="ml-1"
            >
              {{ $t("validation.minChars", { chars: 3 }) }}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              v-else-if="!$v.token.label.maxLength"
              class="ml-1"
            >
              {{ $t("validation.maxChars", { chars: 255 }) }}
            </b-form-invalid-feedback>
          </div>
        </div>

        <div class="form-group row align-items-center">
          <label class="col-xl-2 col-lg-2 col-md-3 col-form-label">
            {{ $t("workflowToken.token") }}
          </label>
          <div class="col-md-9 col-lg-10 col-xl-9">
            <div class="input-group">
              <div class="input-group-prepend">
                <b
                  class="btn btn-icon btn-light"
                  @click="copyText(token.value)"
                >
                  <i class="fal fa-copy" />
                </b>
              </div>
              <b-form-input
                v-model="token.value"
                type="text"
                class="form-control pr-0"
                :class="
                  !$v.token.value.required ||
                  !$v.token.value.allowedTokenCharsValidator
                    ? 'is-invalid'
                    : 'is-valid'
                "
                @input="validateFields"
              />
              <div class="input-group-append">
                <b class="btn btn-icon btn-light" @click="generateToken">
                  <i class="fal fa-arrows-rotate"></i>
                </b>
              </div>
              <b-form-invalid-feedback
                v-if="!$v.token.value.required"
                class="ml-1"
              >
                {{ $t("validation.required.token") }}
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                v-else-if="!$v.token.value.allowedTokenCharsValidator"
                class="ml-1"
              >
                Token enthält ungültige Zeichen
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <div class="form-group row align-items-top">
          <label class="col-xl-2 col-lg-2 col-md-3 col-form-label">
            {{ $t("workflowToken.whitelistingActive") }}
          </label>
          <div class="col-md-9 col-lg-10 col-xl-9">
            <span class="switch switch-sm">
              <label>
                <input v-model="token.ip_whitelist_active" type="checkbox" />
                <span></span>
              </label>
            </span>
          </div>
        </div>
        <div
          v-show="token.ip_whitelist_active"
          class="form-group row align-items-top"
        >
          <label class="col-xl-2 col-lg-2 col-md-3 col-form-label mt-4">
            {{ $t("workflowToken.whitelistIps") }}
          </label>
          <div class="col-md-9 col-lg-10 col-xl-9">
            <MultiIpInput v-model="token.ip_whitelist" />
            <!--b-list-group>
              <b-list-group-item
                v-for="(ip, key) in token.ip_whitelist"
                :key="key"
                class="d-flex justify-content-between"
              >
                <span>{{ ip }}</span>
                <button
                  class="btn btn-icon btn-danger btn-xs"
                  @click="removeIpFromList(key)"
                >
                  <i class="fal fa-minus" />
                </button>
              </b-list-group-item>
            </b-list-group>
            <div class="input-group mt-1">
              <input
                v-model="ip"
                type="text"
                class="form-control pr-0"
                :class="ipValidClass"
                placeholder="IP"
                @input="validIpInput"
              />
              <div
                class="input-group-append"
                :style="ipValid ? 'cursor: pointer' : ''"
                @click="addIpToList"
              >
                <span class="input-group-text">
                  <i class="fal fa-plus"></i>
                </span>
              </div>
            </div-->
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import processToken from "@/components/Projects/Settings/WorkflowToken/processToken";
import MultiIpInput from "@/components/Tools/MultiIpInput";
import {
  maxLength,
  minLength,
  required,
  helpers
} from "vuelidate/lib/validators";

const allowedTokenCharsValidator = helpers.regex(
  "allowedTokenChars",
  /^[A-Za-z0-9-_.~]*$/
);

export default {
  components: {
    MultiIpInput
  },
  props: {
    showTitle: {
      type: Boolean,
      default: true
    },
    showCancelButton: {
      type: Boolean,
      default: true
    },
    returnToRoute: {
      type: Boolean,
      default: true
    }
  },
  emits: ["token-saved"],
  data() {
    return {
      edit: false,
      isValid: false,
      isBusy: false,
      token: {
        label: "",
        value: "",
        ip_whitelist: [],
        ip_whitelist_active: true
      },
      ip: "",
      ipValid: true
    };
  },
  computed: {
    ipValidClass() {
      if (this.ip.length === 0) {
        return "";
      }
      return !this.ipValid ? "is-invalid" : "is-valid";
    }
  },
  validations: {
    token: {
      label: { required, minLength: minLength(3), maxLength: maxLength(255) },
      value: { required, allowedTokenCharsValidator }
    }
  },
  mounted() {
    const id = this.$route.params.tokenId;
    this.edit = id !== undefined;
    if (this.edit) {
      this.loadToken();
    }
  },
  methods: {
    loadToken() {
      const id = this.$route.params.tokenId;

      this.isBusy = true;

      processToken
        .get(id)
        .then(response => {
          this.token = response.data.data;
          this.validateFields();
          this.isBusy = false;
        })
        .catch(() => {
          this.isBusy = false;
        });
    },
    validateFields() {
      this.$v.$touch();
      this.isValid =
        !this.$v.token.label.$invalid && !this.$v.token.value.$invalid;
    },
    copyText(text) {
      this.$copyText(text);
      this.$toast.fire({
        icon: "info",
        title: this.$t("general.copied")
      });
    },
    generateToken() {
      this.token.value = this.random() + this.random();
      this.validateFields();
    },
    random() {
      return Math.random().toString(36).substr(2);
    },

    saveToken() {
      if (!this.edit) {
        this.storeToken();
        return;
      }
      this.updateToken();
    },
    storeToken() {
      this.isBusy = true;

      processToken
        .store(this.token)
        .then(response => {
          this.token = response.data.data;
          this.showSavedToast(this.token.label);
          this.isBusy = false;

          const deepCopy = JSON.parse(JSON.stringify(this.token));
          this.$emit("token-saved", deepCopy);

          this.token = {
            label: "",
            value: "",
            ip_whitelist: []
          };
          this.isValid = false;
        })
        .catch(error => {
          console.log(error);
          this.isBusy = false;
        });
    },
    updateToken() {
      this.isBusy = true;
      const id = this.$route.params.tokenId;
      processToken
        .update(id, this.token)
        .then(() => {
          this.showSavedToast(this.token.label);
          this.isBusy = false;

          this.$emit("token-saved", this.token);
        })
        .catch(error => {
          console.log(error);
          this.isBusy = false;
        });
    },
    showSavedToast(name) {
      this.$toast.fire({
        icon: "success",
        title: this.$t("workflowToken.saved", { name: name })
      });
    },
    cancel() {
      if (this.returnToRoute) {
        this.$router.push({ name: "projectSettingsWorkflowToken" });
      }
      this.$emit("token-create-cancel");
    }
  }
};
</script>

<style lang="scss" scoped>
.input-group-prepend {
  .input-group-text {
    border: 1px solid #e4e6ef;
  }
}

.input-group-prepend,
.input-group-append {
  .btn {
    border: 1px solid #e4e6ef;
    min-width: 45px;
  }
}
</style>
