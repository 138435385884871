<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <Users></Users>
      </div>
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" v-if="appVersion < 2">
        <Roles></Roles>
      </div>
    </div>
  </div>
</template>

<script>
import Users from "@/components/Projects/Settings/Users/Table";
import Roles from "@/components/Admins/Roles/Table";

export default {
  name: "ProjectsUsersIndex",
  components: {
    Users,
    Roles
  }
};
</script>
