import ApiService from "@/core/services/api.service";
import store from "@/core/services/store";
let url = process.env.VUE_APP_API_TENANT;
if (process.env.VUE_APP_VERSION >= 2) {
  url = process.env.VUE_APP_API_ADMIN;
}
ApiService.setHeader("x-api-key", store.getters.apiToken);
if (store.getters.apiTokenPartner) {
  ApiService.setHeader("x-partner-key", store.getters.apiTokenPartner);
}

class mailRecipients {
  get(id) {
    return ApiService.get(url, "mail_recipients/" + id);
  }
  getAll(params) {
    var options = "?page=" + params.page + "&perPage=" + params.size;
    return ApiService.get(url, "mail_recipients" + options);
  }
  store(data) {
    return ApiService.post(url + "/mail_recipients", data);
  }
  update(roleId, data) {
    return ApiService.put(url + "/mail_recipients/" + roleId, data);
  }
  delete(id) {
    return ApiService.delete(url + "/mail_recipients/" + id);
  }
}
export default new mailRecipients();
