<template>
  <div id="queuesCard" class="card card-custom grid-stack-item-content">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">{{ $t("queues.queues") }}</h3>
      </div>
      <div class="card-toolbar">
        <button class="btn btn-primary btn-hover" @click="createQueues">
          <i class="fal fa-plus"></i>
          {{ $t("queues.queueCreate") }}
        </button>
      </div>
    </div>

    <v-progress-linear v-if="isBusy" indeterminate color="primary" />

    <div class="card-body column">
      <TableWrapper :meta="meta" @reload-data="loadQueues">
        <b-table
          responsive
          :items="queues"
          :fields="fields"
          class="dataTable table table-head-custom"
          :show-empty="true"
          :empty-text="$t('table.noRecords')"
        >
          <template #cell(active)="data">
            <span
              v-if="data.item.active"
              class="label label-lg label-light-success label-inline"
            >
              {{ $t("general.active") }}
            </span>
            <span
              v-else
              class="label label-lg label-light-danger label-inline"
            >
              {{ $t("general.inactive") }}
            </span>
          </template>

          <template #cell(actions)="data">
            <b
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="showDetails(data.item.id)"
            >
              <i class="fal fa-pen"></i>
            </b>
            <b
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="deleteQueue(data.item.id, data.item.name)"
            >
              <i class="fal fa-trash"></i>
            </b>
          </template>
        </b-table>
      </TableWrapper>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Queues from "@/components/Projects/Settings/Queues/queues";
import { mapGetters } from "vuex";
import TableWrapper from "@/components/Tools/TableWrapper";
import { formatDate } from "@/components/Tools/modifiers";
import { bus } from "@/main";

export default {
  name: "ProjectsSettingsQueuesTable",
  components: {
    TableWrapper
  },
  data() {
    return {
      queues: [],
      searchTitle: "",
      fields: [
        {
          key: "id",
          label: this.$t("table.id"),
          sortable: true,
          thStyle: { width: "70px" }
        },
        {
          key: "raw_name",
          label: this.$t("queues.name"),
          sortable: true
        },
        {
          key: "instances",
          label: this.$t("queues.instances"),
          sortable: true
        },
        {
          key: "active",
          label: this.$t("general.status"),
          sortable: true
        },
        {
          key: "updated_at",
          formatter: "formatDateAssigned",
          label: this.$t("table.updatedAt"),
          sortable: true
        },
        {
          key: "created_at",
          formatter: "formatDateAssigned",
          label: this.$t("table.createdAt"),
          sortable: true
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          sortable: false,
          thStyle: { width: "170px" }
        }
      ],
      meta: {},
      isBusy: false
    };
  },
  computed: {
    ...mapGetters(["selectedClient"]),
    ...mapGetters("route", ["requestParams", "cancelToken"])
  },
  watch: {
    selectedClient: function() {
      this.loadQueues();
    }
  },
  mounted() {
    this.loadQueues();
  },
  methods: {
    createQueues() {
      this.$router.push({ name: "projectSettingsQueuesCreate" });
    },
    loadQueues() {
      if (!this.$store.getters.apiToken && this.appVersion < 2) {
        return;
      }

      if (this.appVersion < 2 && this.selectedClient === null) {
        this.queues = [];
        return false;
      }

      this.isBusy = true;
      let params = this.requestParams();
      let cancelToken = this.cancelToken();

      Queues.getAll(params, "", cancelToken)
        .then(response => {
          this.queues = response.data.data;
          this.meta = response.data.meta;
          this.isBusy = false;
        })
        .catch(error => {
          this.$error(error);
          this.isBusy = this.axios.isCancel(error);
        });
    },
    formatDateAssigned(value) {
      return formatDate(value, "DD.MM.YYYY");
    },
    showDetails(id) {
      this.$router.push({
        name: "projectSettingsQueuesEdit",
        params: { queueId: id }
      });
    },
    deleteQueue(id, name) {
      Swal.fire({
        title: this.$t("queues.deleteConfirm", { queue: name }),
        icon: "warning",
        showConfirmButton: true,
        confirmButtonText: this.$t("general.remove"),
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonText: this.$t("general.cancel")
      }).then(result => {
        if (result.isConfirmed) {
          Queues.delete(id)
            .then(() => {
              this.showDeletedToast(name);
              this.loadQueues();
              bus.$emit("reloadHeaderFilter");
            })
            .catch(error => {
              Swal.fire({
                title: this.$t("general.caution"),
                text: error.response.data.message,
                icon: "error"
              });
              this.isBusy = false;
            });
        }
      });
    },
    showDeletedToast(name) {
      this.$toast.fire({
        icon: "success",
        title: this.$t("queues.deleteSucessText", { queue: name })
      });
    }
  }
};
</script>
