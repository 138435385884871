<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <ProjectsTable v-if="$route.name === 'projectSettingsProjects'" />
        <ProjectsCreate
          v-else-if="$route.name === 'projectSettingsProjectsCreate'"
        />
        <ProjectsDetails
          v-else-if="$route.name.includes('projectSettingsProjectsDetails')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ProjectsTable from "@/components/Projects/Settings/Projects/Table";
import ProjectsCreate from "@/components/Projects/Settings/Projects/Create";
import ProjectsDetails from "@/components/Projects/Settings/Projects/Detail";

export default {
  name: "ProjectsSettingsProjectsIndex",
  components: {
    ProjectsTable,
    ProjectsCreate,
    ProjectsDetails
  },
  data() {
    return {
      clientSelected: false,
      isBusy: false
    };
  },
  watch: {
    $route: function () {
      this.setBreadcrumbs();
    }
  },
  mounted() {
    this.setBreadcrumbs();
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.$t("menu.projectSettings"),
          route: ""
        },
        {
          title: this.$t("menu.projectSettingsProjects"),
          route: ""
        },
        {
          title: this.$t("general.overview"),
          route: "/project/settings/projects"
        }
      ]);
    }
  }
};
</script>
