<template>
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title d-flex align-items-center">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("projects.informationProject") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-secondary mr-2"
          @click="$router.push({ name: 'projectSettingsProjects' })"
        >
          {{ $t("general.cancel") }}
        </button>
        <button
          :class="isValid ? 'btn-primary' : 'btn-secondary'"
          class="btn"
          @click="storeProject"
        >
          {{ $t("general.save") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="form-group row align-items-center">
          <label class="col-xl-2 col-lg-2 col-md-3 col-form-label">
            {{ $t("projects.name") }} <span class="text-danger">*</span>
          </label>
          <div class="col-md-9 col-lg-8 col-xl-8">
            <b-form-input
              v-model="project.name"
              type="text"
              class="form-control"
              :class="
                !$v.project.name.required ||
                !$v.project.name.minLength ||
                !$v.project.name.maxLength
                  ? 'is-invalid'
                  : 'is-valid'
              "
              @input="validateFields"
            />
            <b-form-invalid-feedback
              v-if="!$v.project.name.required"
              id="projectName-feedback"
              class="ml-1"
            >
              {{ $t("validation.required.name") }}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              v-if="!$v.project.name.minLength"
              id="projectName-feedback"
              class="ml-1"
            >
              {{ $t("validation.minChars", { chars: 3 }) }}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              v-if="!$v.project.name.maxLength"
              id="projectName-feedback"
              class="ml-1"
            >
              {{ $t("validation.maxChars", { chars: 255 }) }}
            </b-form-invalid-feedback>
          </div>
        </div>

        <div class="form-group row align-items-center">
          <label class="col-xl-2 col-lg-2 col-md-3 col-form-label">
            {{ $t("projects.short") }}
          </label>
          <div class="col-md-9 col-lg-8 col-xl-8">
            <b-form-input
              v-model="project.short"
              type="text"
              class="form-control"
            />
          </div>
        </div>

        <div class="form-group row align-items-start">
          <label class="col-xl-2 col-lg-2 col-md-3 col-form-label">
            {{ $t("projects.description") }}
          </label>
          <div class="col-md-9 col-lg-8 col-xl-8">
            <quill-editor
              ref="myTextEditor"
              class="editor"
              :options="editorOptions"
              :value="project.description"
              @change="onEditorChange"
            />
          </div>
        </div>

        <div class="form-group row align-items-center">
          <label class="col-xl-2 col-lg-2 col-md-3 col-form-label">
            {{ $t("projects.startedAt") }}
          </label>
          <div class="col-md-9 col-lg-8 col-xl-8">
            <b-form-datepicker
              v-model="project.started_at"
              class="form-control"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
              }"
              locale="de"
              :placeholder="$t('projects.noDateSelected')"
              @input="validateFields"
            />
          </div>
        </div>

        <div class="form-group row align-items-center">
          <label class="col-xl-2 col-lg-2 col-md-3 col-form-label">
            {{ $t("projects.finishedAt") }}
          </label>
          <div class="col-md-9 col-lg-8 col-xl-8">
            <b-form-datepicker
              v-model="project.finished_at"
              class="form-control"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
              }"
              locale="de"
              :class="!validations.dates ? 'is-invalid' : ''"
              :placeholder="$t('projects.noDateSelected')"
              @input="validateFields"
            />
            <b-form-invalid-feedback
              v-if="!validations.dates"
              id="finishedAt-feedback"
              class="ml-1"
            >
              {{ $t("validation.dates") }}
            </b-form-invalid-feedback>
          </div>
        </div>

        <div class="form-group row align-items-center">
          <label class="col-xl-2 col-lg-2 col-md-3 col-form-label">
            {{ $t("projects.url") }}
          </label>
          <div class="col-md-9 col-lg-8 col-xl-8">
            <div class="row">
              <div class="col pr-0">
                <b-form-input
                  v-model="project.url"
                  type="text"
                  class="form-control"
                  @input="validateFields"
                />
              </div>
              <div class="col-auto m-auto">
                <button
                  class="btn btn-primary"
                  :disabled="!isValidURL"
                  @click.prevent="getImageFromUrl"
                >
                  {{ $t("projects.loadImage") }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group row align-items-start">
          <label class="col-xl-2 col-lg-2 col-md-3 col-form-label mt-1">
            {{ $t("projects.image") }}
          </label>
          <div class="col-md-9 col-lg-8 col-xl-8">
            <div id="profile-image" class="image-input image-input-outline">
              <div
                class="image-input-wrapper"
                :style="
                  project.image
                    ? 'background-image: url(' +
                      project.image +
                      '); background-color: #ffffff; background-size: contain;'
                    : ''
                "
              ></div>
              <label
                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="change"
                @click="clickUpload"
              >
                <i class="fa fa-pen icon-sm text-muted"></i>
              </label>
              <span
                v-if="project.image"
                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="remove"
                @click="project.image = ''"
              >
                <i class="ki ki-bold-close icon-xs text-muted"></i>
              </span>
            </div>
            <v-file-input
              ref="imageInput"
              v-model="project.imageFile"
              outlined
              dense
              hide-details
              class="d-none"
              @change="onFileChange"
            />
          </div>
        </div>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import Projects from "@/components/Projects/Settings/Projects/project";
import Brandfetch from "@/components/Projects/Settings/Projects/brandfetch";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { maxLength, minLength, required } from "vuelidate/lib/validators";
import debounce from "lodash/debounce";
import { bus } from "@/main";

export default {
  name: "ProjectsSettingsProjectsCreate",
  data() {
    return {
      isUpdate: false,
      isValid: false,
      isValidURL: false,
      project: {
        name: ""
      },
      validations: {
        dates: true
      },
      editorOptions: {
        placeholder: this.$t("projects.description")
      },
      isBusy: false
    };
  },
  validations: {
    project: {
      name: { required, minLength: minLength(3), maxLength: maxLength(255) }
    }
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.$t("menu.projectSettings"),
          route: "/project/settings/projects"
        },
        {
          title: this.$t("menu.projectSettingsProjects"),
          route: "/project/settings/projects"
        },
        {
          title: this.$t("projects.projectCreate"),
          route: ""
        }
      ]);
    },
    validateFields() {
      this.$v.$touch();
      this.isValid = !this.$v.project.name.$invalid;

      this.validations.dates = true;

      if (
        this.project.started_at &&
        this.project.finished_at &&
        this.project.finished_at < this.project.started_at
      ) {
        this.validations.dates = false;
        this.isValid = false;
      }

      this.isValidURL = this.project.url && this.validURL(this.project.url);
    },
    storeProject() {
      let project = this.project;
      Projects.store(project)
        .then(response => {
          this.project = response.data.data;
          this.isBusy = false;
          this.showSavedToast(project.name);
          this.$router.push({
            name: "projectSettingsProjectsDetails",
            params: { projectId: this.project.id }
          });
          bus.$emit("reloadHeaderFilter");
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    showSavedToast(name) {
      this.$toast.fire({
        icon: "success",
        title: this.$t("projects.saved", { name: name })
      });
    },
    onFileChange(file) {
      if (!file) return;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => this.$set(this.project, "image", reader.result);
    },
    clickUpload() {
      this.$refs.imageInput.$refs.input.click();
    },
    getImageFromUrl() {
      this.isBusy = true;
      Brandfetch.getLogo(this.project.url)
        .then(image => {
          this.onFileChange(image);
          this.isBusy = false;
        })
        .catch(error => {
          console.log(error);
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: this.$t("projects.noImageFound"),
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    validURL(str) {
      const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(str);
    },
    onEditorChange: debounce(function (value) {
      let html = value.html;
      this.project.description = html;
    }, 466)
  },
  mounted() {
    this.setBreadcrumbs();
  }
};
</script>

<style>
.ql-container {
  min-height: 10vh;
}
</style>

<style scoped>
.image-input {
  width: 100%;
}

.image-input-wrapper {
  height: 240px;
  width: 100%;
}
</style>
