var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-custom grid-stack-item-content",attrs:{"id":"projectsCard"}},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-title"},[_c('h3',{staticClass:"card-label"},[_vm._v(_vm._s(_vm.$t("projects.title")))])]),_c('div',{staticClass:"card-toolbar"},[_c('button',{staticClass:"btn btn-primary btn-hover",on:{"click":_vm.createProject}},[_c('i',{staticClass:"fal fa-plus"}),_vm._v(" "+_vm._s(_vm.$t("projects.projectCreate"))+" ")])])]),(_vm.isBusy)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('div',{staticClass:"card-body column"},[_c('TableWrapper',{attrs:{"meta":_vm.meta},on:{"reload-data":_vm.loadProjects}},[_c('b-table',{staticClass:"dataTable table table-head-custom",attrs:{"responsive":"","items":_vm.projects,"fields":_vm.fields,"show-empty":true,"empty-text":_vm.$t('table.noRecords')},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_c('div',{staticClass:"row flex-wrap py-3"},[_c('div',{staticClass:"col-auto symbol py-0"},[_c('div',{staticClass:"symbol-label symbol-label-profile",style:(typeof data.item.image === 'object' &&
                  Object.keys(data.item.image).length
                    ? 'background-image: url(' +
                      'data:' +
                      data.item.image.mimeType +
                      ';base64,' +
                      data.item.image.base64 +
                      '); background-color: #ffffff;'
                    : '')})]),_c('div',{staticClass:"col-auto flex-grow-1 py-0",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.showDetails(data.item.id)}}},[_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(data.item.name))]),_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(data.item.short))])])])]}},{key:"cell(started_at)",fn:function(data){return [_c('div',{staticClass:"row flex-wrap py-3"},[_c('div',{staticClass:"col-auto flex-grow-1 py-0"},[(data.item.started_at)?_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm.formatDateAssigned(data.item.started_at))+" ")]):_c('span',{staticClass:"d-block"},[_vm._v("-")])])])]}},{key:"cell(finished_at)",fn:function(data){return [_c('div',{staticClass:"row flex-wrap py-3"},[_c('div',{staticClass:"col-auto flex-grow-1 py-0"},[(data.item.finished_at)?_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm.formatDateAssigned(data.item.finished_at))+" ")]):_c('span',{staticClass:"d-block"},[_vm._v("-")])])])]}},{key:"cell(actions)",fn:function(data){return [_c('b',{staticClass:"btn btn-icon btn-light btn-sm mr-1",on:{"click":function($event){return _vm.showDetails(data.item.id)}}},[_c('i',{staticClass:"fal fa-pen"})]),_c('b',{staticClass:"btn btn-icon btn-light btn-sm mr-1",on:{"click":function($event){return _vm.deleteProject(data.item)}}},[_c('i',{staticClass:"fal fa-trash"})])]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }