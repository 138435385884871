const URL = process.env.VUE_APP_BRANDFETCH_LOGO_URL;
const API_KEY = process.env.VUE_APP_BRANDFETCH_API_KEY;

class Brandfetch {
  getLogo(domain) {
    if (URL === undefined || API_KEY === undefined) {
      return new Promise((resolve, reject) => {
        reject({ response: "URL or Apikey not found" });
      });
    }

    const requestOptions = {
      method: "POST",
      headers: { "x-api-key": API_KEY },
      body: JSON.stringify({ domain: domain })
    };

    return new Promise((resolve, reject) => {
      fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
          let logoUrl = null;
          if (data.response.logo && data.response.logo.image) {
            logoUrl = data.response.logo.image;
          }
          if (!logoUrl && data.response.icon && data.response.icon.image) {
            logoUrl = data.response.icon.image;
          }
          if (!logoUrl) {
            reject(data);
          }

          fetch(logoUrl)
            .then(logoResponse => logoResponse.blob())
            .then(blob => {
              resolve(blob);
            })
            .catch(error => reject(error));
        })
        .catch(error => reject(error));
    });
  }
}

export default new Brandfetch();
