<template>
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title d-flex align-items-center">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("queues.queues") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-secondary mr-2"
          @click="$router.push({ name: 'projectSettingsQueues' })"
        >
          {{ $t("general.cancel") }}
        </button>
        <button
          :class="isValid ? 'btn-primary' : 'btn-secondary'"
          class="btn"
          @click="updateQueue"
        >
          {{ $t("general.save") }}
        </button>
      </div>
    </div>

    <v-progress-linear v-if="isBusy" indeterminate color="primary" />

    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="form-group row align-items-center">
          <label class="col-xl-2 col-lg-2 col-md-3 col-form-label">
            {{ $t("queues.name") }} <span class="text-danger">*</span>
          </label>
          <div class="col-md-9 col-lg-8 col-xl-8">
            <b-form-input
              v-model="queue.raw_name"
              type="text"
              class="form-control"
              :class="
                !$v.queue.raw_name.required ||
                !$v.queue.raw_name.minLength ||
                !$v.queue.raw_name.maxLength
                  ? 'is-invalid'
                  : 'is-valid'
              "
              @input="validateFields"
            />
            <b-form-invalid-feedback
              v-if="!$v.queue.raw_name.required"
              id="projectName-feedback"
              class="ml-1"
            >
              {{ $t("validation.required.name") }}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              v-if="!$v.queue.raw_name.minLength"
              id="projectName-feedback"
              class="ml-1"
            >
              {{ $t("validation.minChars", { chars: 3 }) }}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              v-if="!$v.queue.raw_name.maxLength"
              id="projectName-feedback"
              class="ml-1"
            >
              {{ $t("validation.maxChars", { chars: 255 }) }}
            </b-form-invalid-feedback>
          </div>
        </div>

        <div class="form-group row align-items-center">
          <label class="col-xl-2 col-lg-2 col-md-3 col-form-label">
            {{ $t("queues.instances") }}
          </label>
          <div class="col-md-9 col-lg-8 col-xl-8">
            <b-form-select
              id="language_id"
              v-model="queue.instances"
              :options="instances"
              @input="validateFields"
            >
            </b-form-select>
          </div>
        </div>

        <div class="form-group row align-items-center">
          <label class="col-xl-2 col-lg-2 col-md-3 col-form-label">
            {{ $t("general.active") }}
          </label>
          <div class="col-md-9 col-lg-8 col-xl-8">
            <span class="switch switch-sm">
              <label>
                <input
                  v-model="queue.active"
                  type="checkbox"
                  @input="validateFields"
                />
                <span></span>
              </label>
            </span>
          </div>
        </div>

        <div class="form-group row align-items-center">
          <label class="col-xl-2 col-lg-2 col-md-3 col-form-label">
            {{ $t("queues.tries") }}
          </label>
          <div class="col-md-9 col-lg-8 col-xl-8">
            <b-form-input
              id="tries"
              v-model="queue.tries"
              type="number"
              min="1"
              @input="validateFields"
            />
          </div>
        </div>

        <div class="form-group row align-items-center">
          <label class="col-xl-2 col-lg-2 col-md-3 col-form-label">
            {{ $t("queues.timeout") }}
          </label>
          <div class="col-md-9 col-lg-8 col-xl-8">
            <b-form-input
              id="timeout"
              v-model="queue.timeout"
              type="number"
              min="1"
              @input="validateFields"
            />
          </div>
        </div>

        <div class="form-group row align-items-center">
          <label class="col-xl-2 col-lg-2 col-md-3 col-form-label">
            {{ $t("queues.memory") }}
          </label>
          <div class="col-md-9 col-lg-8 col-xl-8">
            <b-form-input
              id="memory"
              v-model="queue.memory"
              type="number"
              min="1"
              @input="validateFields"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Queues from "@/components/Projects/Settings/Queues/queues";
import { maxLength, minLength, required } from "vuelidate/lib/validators";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { LOAD_PROJECTS } from "@/core/services/store/switchArea.module";

export default {
  name: "ProjectsSettingsQueuesEdit",
  data() {
    return {
      instances: [],
      isUpdate: false,
      isValid: false,
      queue: {
        raw_name: "",
        active: false,
        tries: 1,
        timeout: 1200,
        memory: 256,
        instances: 1
      },
      isBusy: false
    };
  },
  mounted() {
    this.loadQueue();
    this.setInstances();
  },
  validations: {
    queue: {
      raw_name: { required, minLength: minLength(3), maxLength: maxLength(255) }
    }
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.$t("menu.projectSettings"),
          route: "/project/settings/queues"
        },
        {
          title: this.$t("menu.projectSettingsQueues"),
          route: "/project/settings/queues"
        },
        {
          title: this.queue.raw_name,
          route: "/project/settings/queues/" + this.queue.id
        }
      ]);
    },
    loadQueue() {
      this.isBusy = true;
      let queueId = this.$route.params.queueId;
      Queues.get(queueId)
        .then(response => {
          this.queue = response.data.data;
          this.setBreadcrumbs();
          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    updateQueue() {
      let queue = this.queue;
      this.isBusy = true;
      Queues.update(queue.id, queue)
        .then(response => {
          this.queue = response.data.data;
          this.isBusy = false;
          this.showSavedToast(queue.raw_name);
          this.$store.dispatch(LOAD_PROJECTS, false);
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    showSavedToast(raw_name) {
      this.$toast.fire({
        icon: "success",
        title: this.$t("queues.queueUpdated", { raw_name: raw_name })
      });
    },
    validateFields() {
      this.$v.$touch();
      this.isValid = !this.$v.queue.raw_name.$invalid;
      this.isValid = !this.$v.queue.active.$invalid;
      this.isValid = !this.$v.queue.memory.$invalid;
      this.isValid = !this.$v.queue.timeout.$invalid;
      this.isValid = !this.$v.queue.tries.$invalid;
      this.isValid = !this.$v.queue.instances.$invalid;
    },
    setInstances() {
      let instances = [];
      for (var i = 0; i <= 12; i++) {
        var tmpObj = {
          text: i,
          value: i
        };
        instances.push(tmpObj);
      }
      this.instances = instances;
    }
  }
};
</script>
