<template>
  <div>
    <b-sidebar
      v-model="showSidebar"
      :title="$t('general.details')"
      right
      :backdrop="true"
      backdrop-variant="dark"
      bg-variant="white"
      shadow
    >
      <b-row class="m-1">
        <b-col md="12">
          <div
            v-if="sidebarValue.type !== 'json'"
            style="overflow-wrap: break-word"
          >
            {{ sidebarValue.value }}
          </div>
          <div
            v-else
            style="overflow-wrap: break-word"
            class="bg-dark text-white"
          >
            <vue-json-pretty :data="sidebarValue.value" />
          </div>
        </b-col>
      </b-row>
    </b-sidebar>
    <div id="configsCard" class="card card-custom grid-stack-item-content">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">{{ $t("config.configurations") }}</h3>
        </div>
        <div class="card-toolbar">
          <button class="btn btn-primary btn-hover" @click="createConfig(null)">
            <i class="fal fa-plus"></i>
            {{ $t("config.configCreate") }}
          </button>
        </div>
      </div>
      <v-progress-linear v-if="isBusy" indeterminate color="primary" />
      <div class="card-body column">
        <TableWrapper :meta="meta" @reload-data="loadConfigs">
          <b-table
            responsive
            :items="configs"
            :fields="fields"
            class="dataTable table table-head-custom"
            :show-empty="true"
            :empty-text="$t('table.noRecords')"
          >
            <template #cell(value)="data">
              <b-row>
                <v-icon class="mr-1" @click="showSidebarInfo(data.item)"
                  >fal fa-info-circle</v-icon
                >
                <span class="truncate-2" style="max-width: 200px">{{
                  data.item.value
                }}</span>
              </b-row>
            </template>
            <template #cell(actions)="data">
              <button
                v-b-tooltip.left.noninteractive="$t('general.copy')"
                class="btn btn-icon btn-light btn-sm mr-1"
                @click.left="createConfig(data.item)"
                @click.middle="createConfig(data.item)"
              >
                <i class="fal fa-copy" />
              </button>
              <button
                v-b-tooltip.top.noninteractive="$t('general.edit')"
                class="btn btn-icon btn-light btn-sm mr-1"
                @click.left="
                  $router.push({
                    name: 'projectSettingsConfigDetails',
                    params: { configId: data.item.id }
                  })
                "
                @click.middle="
                  $router.push({
                    name: 'projectSettingsConfigDetails',
                    params: { configId: data.item.id }
                  })
                "
              >
                <i class="fal fa-pen" />
              </button>
              <button
                v-b-tooltip.top.noninteractive="$t('general.delete')"
                class="btn btn-icon btn-light btn-sm mr-1"
                @click="deleteConfig(data.item.id, data.item.name)"
              >
                <i class="fal fa-trash" />
              </button>
            </template>
          </b-table>
        </TableWrapper>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { formatDate } from "@/components/Tools/modifiers";
import Config from "@/components/Projects/Settings/Config/config";
import VueJsonPretty from "vue-json-pretty";
import TableWrapper from "@/components/Tools/TableWrapper";
import { mapGetters } from "vuex";

export default {
  name: "ProjectsSettingsConfigTable",
  components: {
    TableWrapper,
    VueJsonPretty
  },
  data() {
    return {
      showSidebar: false,
      sidebarValue: "",
      configs: [],
      searchTitle: "",
      // Meta info
      meta: {},
      isBusy: false,
      adminerHeaders: {
        headers: {
          Accept:
            "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9",
          "Content-Type": "multipart/form-data"
        }
      }
    };
  },
  computed: {
    ...mapGetters("route", ["requestParams", "cancelToken"]),
    fields() {
      return [
        {
          key: "id",
          label: this.$t("table.id"),
          sortable: true,
          thStyle: { width: "60px" }
        },
        {
          key: "name",
          label: this.$t("config.name"),
          sortable: true,
          thStyle: { width: "200px" }
        },
        {
          key: "label",
          label: this.$t("config.label"),
          sortable: true,
          thStyle: { width: "200px" }
        },
        {
          key: "type",
          label: this.$t("config.type"),
          sortable: true,
          thStyle: { width: "100px" }
        },
        {
          key: "value",
          label: this.$t("config.value"),
          sortable: true,
          thStyle: { width: "500px" }
        },
        {
          key: "created_at",
          formatter: "formatDateAssigned",
          label: this.$t("table.createdAt"),
          sortable: true,
          thStyle: { "min-width": "180px" }
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          thStyle: { "min-width": "140px" }
        }
      ];
    }
  },
  mounted() {
    this.loadConfigs();
    this.$root.$on("configsTableRefresh", () => {
      this.loadConfigs();
    });
  },
  methods: {
    loadConfigs() {
      this.isBusy = true;
      let params = this.requestParams();
      let cancelToken = this.cancelToken();
      Config.getAll(params, cancelToken)
        .then(response => {
          this.configs = response.data.data;
          this.meta = response.data.meta;
          this.isBusy = false;
        })
        .catch(error => {
          this.$error(error);
          this.isBusy = this.axios.isCancel(error);
        });
    },
    formatDateAssigned(value) {
      return formatDate(value);
    },
    createConfig(copyFrom) {
      this.$emit("create-config", copyFrom);
    },
    deleteConfig(id, name) {
      Swal.fire({
        title: this.$t("config.configDeleteTitle"),
        text: this.$t("config.configDeleteMessage", { config: name }),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("general.cancel"),
        confirmButtonText: this.$t("general.delete")
      }).then(result => {
        if (result.isConfirmed) {
          Config.delete(id)
            .then(() => {
              this.$toast.fire({
                icon: "success",
                title: this.$t("config.configDeleted")
              });
              if (this.configs.length === 1 && this.page !== 1) {
                this.handlePageChange(this.page - 1);
              } else {
                this.loadConfigs();
              }
            })
            .catch(error => {
              Swal.fire("Error", error.response.data.message, "error");
            });
        }
      });
    },
    showSidebarInfo(data) {
      this.sidebarValue = data;
      this.showSidebar = true;
    }
  }
};
</script>
